import React from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import validator from 'validator';
import '../../assets/validatedCheckbox.css';

import { Alert, Form, Button, Row, Col } from 'react-bootstrap';

import MainComponent from '../../commons/main/mainComponent';

const RegisterView = ({
    success,
    message,
    handleRegistration,
    handleInputChange,
}) => {
    const registerForm = (
        <>
            <h3 className="text-center">Liity jäseneksi</h3>
            <p>
                Voit jättää jäsenhakemuksesi Digit ry:lle tästä. Täytön jälkeen
                sähköpostiisi lähetetään salasana, jolla pääset kirjautumaan,
                joten varmistathan että sähköpostiosoite on oikein.
            </p>
            <p>
                Kun käyttäjä on luotu, kirjaudu sisään ja maksa jäsenmaksu.
                Jäsenyys astuu voimaan, kun jäsenmaksu on maksettu. Jos
                jäsenmaksua ei ole maksettu 30 päivän kuluessa, käyttäjä
                poistetaan.
            </p>
            <p>Digit ry:n hallitus hyväksyy jäsenyytesi mahdollisimman pian.</p>
            <p>
                <a href="https://digit.fi/viralliset/tietosuojaseloste">
                    Lisätietoa jäsenrekisteristä
                </a>
            </p>
            <ValidationForm
                className="register-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleRegistration(e);
                }}
            >
                <Row>
                    <Col md>
                        <Form.Group>
                            <Form.Label>Etunimi</Form.Label>
                            <TextInput
                                name="firstName"
                                id="firstName"
                                errorMessage={{
                                    required: 'Etunimi on pakollinen.',
                                    pattern: 'Tarkista etunimi.',
                                }}
                                required
                                pattern="[a-zA-Z\u00c0-\u017e- ]{2,20}$"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md>
                        <Form.Group>
                            <Form.Label>Sukunimi</Form.Label>
                            <TextInput
                                name="lastName"
                                id="lastName"
                                errorMessage={{
                                    required: 'Sukunimi on pakollinen.',
                                    pattern: 'Tarkista sukunimi.',
                                }}
                                required
                                pattern="[a-zA-Z\u00c0-\u017e- ]{2,25}$"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <Form.Group>
                            <Form.Label>UTU-tunnus</Form.Label>
                            <TextInput
                                name="utuAccount"
                                id="utuAccount"
                                errorMessage={{
                                    required: 'UTU-tunnus on pakollinen.',
                                    pattern: 'Tarkista UTU-tunnus.',
                                }}
                                pattern="[a-öA-Ö]{2,20}$"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md>
                        <Form.Group>
                            <Form.Label>Sähköposti</Form.Label>
                            <TextInput
                                name="email"
                                id="email"
                                errorMessage={{
                                    required: 'Sähköpostiosoite on pakollinen.',
                                    validator: 'Tarkista sähköpostiosoite.',
                                }}
                                validator={validator.isEmail}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md>
                        <Form.Group>
                            <Form.Label>Kotikunta</Form.Label>
                            <TextInput
                                name="homeTown"
                                id="homeTown"
                                errorMessage={{
                                    required: 'Kotikunta on pakollinen.',
                                    pattern: 'Tarkista kotikunta.',
                                }}
                                required
                                pattern="[a-zA-Z\u00c0-\u017e- ]{2,25}$"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md></Col>
                </Row>
                <Form.Group>
                    <Form.Check
                        inline
                        type="checkbox"
                        className="tyy-member"
                        name="tyyMember"
                        color="white"
                        style={{ color: 'white' }}
                        label={
                            <span style={{ color: 'white' }}>TYYn jäsen</span>
                        }
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        inline
                        type="checkbox"
                        name="acceptTerms"
                        className="accept-terms"
                        color="white"
                        id="acceptTerms"
                        style={{ color: 'white' }}
                        label={
                            <span style={{ color: 'white' }}>
                                Hyväksyn että tietojani käsitellään
                                jäsenrekisteriselosteen mukaisesti
                            </span>
                        }
                        onChange={handleInputChange}
                    />
                </Form.Group>
                {message ? (
                    <Alert variant={!success ? 'danger' : 'success'}>
                        {message}
                    </Alert>
                ) : null}
                <Button type="submit" variant="success">
                    <FontAwesomeIcon icon="sign-in-alt" /> Luo tunnus
                </Button>
            </ValidationForm>
            <hr />
            <div className="btm-links">
                <p>
                    <Link to="/">Takaisin</Link>
                </p>
            </div>
        </>
    );

    const registrationComplete = (
        <>
            <h3 className="text-center">Käyttäjä luotu onnistuneesti!</h3>
            <p>Sinulle on luotu käyttäjä onnistuneesti.</p>

            <p>
                Saat sähköpostiisi salasanan, jolla pääset kirjautumaan
                käyttäjärekisteriin. Jos et saa sähköpostia, tarkista
                roskapostikansio. Kirjaudu sisään käyttäjällesi ja maksa
                jäsenmaksu. Jäsenyys astuu voimaan, kun jäsenmaksu on maksettu
                ja hallitus on hyväksynyt jäsenyytesi. Jos jäsenmaksua ei ole
                maksettu 30 päivän kuluessa, käyttäjä poistetaan.
            </p>
            <div className="btm-links">
                <p>
                    <Link to="/">Takaisin</Link>
                </p>
            </div>
        </>
    );
    return (
        <MainComponent big="true">
            {success ? registrationComplete : registerForm}
        </MainComponent>
    );
};

export default RegisterView;
