import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../../utils/api';
import { getCookie } from '../../../utils/cookies';
import PayReturnView from './payReturnView';

class payReturnComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            purchaseId: null,
            purchase: null,
            isLoading: true,
            intervalId: null,
        };
    }

    componentDidMount() {
        const successParam = this.props.match.params.success;
        const purchaseIdParam = this.props.match.params.purchase;

        if (successParam && purchaseIdParam) {
            this.setState({
                success: successParam === 'true',
                purchaseId: purchaseIdParam,
            });

            const intervalId = setInterval(async () => {
                this.fetchPurchase();
            }, 4000);

            this.setState({
                intervalId: intervalId,
            });
        }
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    async fetchPurchase() {
        try {
            const response = await api.get('/purchase/purchase', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    purchaseId: this.state.purchaseId,
                },
            });

            const fetchedPurchase = response.data;

            if (
                fetchedPurchase.status === 'paid' ||
                fetchedPurchase.status === 'cancelled'
            ) {
                if (this.state.intervalId) {
                    clearInterval(this.state.intervalId);
                }
                this.setState({
                    purchase: fetchedPurchase,
                    isLoading: false,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { success, purchase, isLoading } = this.state;

        return (
            <PayReturnView
                success={success}
                purchase={purchase}
                isLoading={isLoading}
            />
        );
    }
}

export default withRouter(payReturnComponent);
