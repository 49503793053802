import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HeaderComponent from '../../commons/header/headerComponent';
import AddMemberView from './addMemberView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';

import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';

class NewMemberComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            memberID: this.props.match.params.id,
            firstName: null,
            lastName: null,
            utuAccount: '',
            email: null,
            homeTown: null,
            tyyMember: null,
            privileges: [],
            membershipSince: null,
            membershipUntil: null,
            accepted: null,
            success: null,
            message: null,
            showModal: false,
        };
        this.handleMembershipStartsChange =
            this.handleMembershipStartsChange.bind(this);
        this.handleMembershipEndsChange =
            this.handleMembershipEndsChange.bind(this);
    }

    handleAddMember = async (event) => {
        event.preventDefault();
        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            utuAccount: this.state.utuAccount,
            email: this.state.email,
            homeTown: this.state.homeTown,
            membershipSince: this.state.membershipSince,
            membershipUntil: this.state.membershipUntil,
            tyyMember: this.state.tyyMember,
            accepted: this.state.accepted,
            memberID: this.state.memberID,
        };

        try {
            const response = await api.post('/admin/new', data, {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            console.log(response.data);

            let privilegeResponse;

            if (response.data.id) {
                privilegeResponse = await api.post(
                    '/admin/privileges',
                    { privileges: this.state.privileges },
                    {
                        headers: {
                            Authorization: getCookie('jasenrekisteri-token'),
                            'Content-Type': 'application/json',
                        },
                        params: {
                            memberID: response.data.id,
                            all: true,
                        },
                    }
                );
                console.log(privilegeResponse.data);
            }

            let modal = false;
            const success =
                response.data.success &&
                response.data.message &&
                (!privilegeResponse || privilegeResponse.data?.success);
            if (success) {
                modal = true;
            }
            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: success,
                    message: response.data.message,
                    showModal: modal,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö jäsenen lisäämiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleMembershipStartsChange = (date) => {
        this.setState({
            membershipSince: date,
        });
    };

    handleMembershipEndsChange = (date) => {
        this.setState({
            membershipUntil: date,
        });
    };

    handlePrivilegeStartChange = (date, index) => {
        this.setState({
            privileges: this.state.privileges.map((privilege, i) => {
                if (i === index) {
                    privilege.startDate = date;
                }
                return privilege;
            }),
        });
    };
    handlePrivilegeEndChange = (date, index) => {
        this.setState({
            privileges: this.state.privileges.map((privilege, i) => {
                if (i === index) {
                    privilege.endDate = date;
                }
                return privilege;
            }),
        });
    };

    handlePrivilegeChange = (index, value) => {
        this.setState({
            privileges: this.state.privileges.map((p, i) => {
                if (i === index) {
                    p.role = value;
                }
                return p;
            }),
        });
    };

    handleRemovePrivilege = (index) => {
        this.setState({
            privileges: this.state.privileges.filter((p, i) => i !== index),
        });
    };

    handleAddPrivilege = () => {
        this.setState({
            privileges: [
                ...this.state.privileges,
                {
                    memberId: this.state.memberID,
                    startDate: Date(),
                    endDate: Date(),
                    role: 'fresher',
                },
            ],
        });
    };

    matchPassword = (value) => {
        return value && value === this.state.password;
    };

    render() {
        let modalClose = () => this.setState({ showModal: false });
        const {
            isLoading,
            firstName,
            lastName,
            utuAccount,
            email,
            homeTown,
            tyyMember,
            privileges,
            membershipSince,
            membershipUntil,
            accepted,
            success,
            message,
            memberID,
            showModal,
        } = this.state;

        if (isLoading === true) {
            return <PreloaderComponent />;
        }

        return (
            <div>
                <HeaderComponent />
                <Modal
                    show={showModal}
                    onHide={modalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ilmoitus
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{message}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Link className="btn btn-success" to="/admin">
                            Takaisin
                        </Link>
                    </Modal.Footer>
                </Modal>
                <AddMemberView
                    isLoading={isLoading}
                    firstName={firstName}
                    lastName={lastName}
                    utuAccount={utuAccount}
                    email={email}
                    homeTown={homeTown}
                    tyyMember={tyyMember}
                    privileges={privileges}
                    membershipSince={membershipSince}
                    membershipUntil={membershipUntil}
                    accepted={accepted}
                    handleAddMember={this.handleAddMember}
                    handleInputChange={this.handleInputChange}
                    handleMembershipStartsChange={
                        this.handleMembershipStartsChange
                    }
                    handleMembershipEndsChange={this.handleMembershipEndsChange}
                    handlePrivilegeStartChange={this.handlePrivilegeStartChange}
                    handlePrivilegeEndChange={this.handlePrivilegeEndChange}
                    handleAddPrivilege={this.handleAddPrivilege}
                    handlePrivilegeChange={this.handlePrivilegeChange}
                    handleRemovePrivilege={this.handleRemovePrivilege}
                    success={success}
                    message={message}
                    memberID={memberID}
                    matchPassword={this.matchPassword}
                />
                <FooterComponent/>
            </div>
        );
    }
}

export default NewMemberComponent;
