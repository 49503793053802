export const announcementStatusString = (status) => {
    switch (status) {
        case 'draft':
            return 'Luonnos';
        case 'scheduled':
            return 'Ajoitettu';
        case 'published':
            return 'Julkaistu';
        case 'archived':
            return 'Arkistoitu';
        case 'template':
            return 'Tiedotepohja';
        default:
            return '-';
    }
};

export const announcementTypeString = (type) => {
    switch (type) {
        case 'club':
            return 'Kerhotiedote';
        case 'weekly':
            return 'Viikkokirje';
        case 'general':
            return 'Yleinen tiedote';
        case 'event':
            return 'Tapahtumatiedote';
        default:
            return type;
    }
};

export const platformString = (platform) => {
    switch (platform) {
        case 'website':
            return 'Tiedotukset - verkkosivut';
        case 'app':
            return 'Tiedotukset - mobiilisovellus';
        case 'emailAll':
            return 'Sähköposti - kaikki';
        case 'emailFreshers':
            return 'Sähköposti - fuksit';
        case 'discord':
            return 'Discord';
        case 'telegram':
            return 'Telegram';
        case 'linkedin':
            return 'LinkedIn';
        default:
            return platform;
    }
};
