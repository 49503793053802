import React, { Component } from 'react';

import './footerComponent.css';

class FooterComponent extends Component {
    render() {
        return (
            <div className="footer-container">
                <a href="https://www.digit.fi/viralliset/tietosuojaseloste/" rel="noopener noreferrer" target="_blank">
                    Jäsenrekisteriseloste
                </a>
                <a href="https://www.digit.fi/" rel="noopener noreferrer" target="_blank">
                    Digitin verkkosivut
                </a>
                <a href="mailto:digit@utu.fi" rel="noopener noreferrer" target="_blank">
                    Digit ry:n hallitus: digit [at] utu.fi
                </a>
            </div>
        );
    }
}

export default FooterComponent;
