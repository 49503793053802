import React, { Component } from 'react';

import HeaderComponent from '../../commons/header/headerComponent';
import MemberPayView from './memberPayView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';

class MemberPayComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            storeItems: [],
            products: [],
            cart: [],
            showCart: false,
            success: null,
            message: null,
        };
    }

    handleBuy = async () => {
        try {
            const paymentIntent = await api.post(
                '/purchase/payment',
                {
                    productIds: this.state.cart.map((p) => p.id),
                },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            const purchaseUrl = paymentIntent.data.purchaseUrl;

            //redirect to purchaseUrl
            window.location.href = purchaseUrl;
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö serverille epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    addToCart = (product) => {
        this.setState({ cart: [...this.state.cart, product] });
    };

    removeFromCart = (product) => {
        const index = this.state.cart.findIndex((p) => p.id === product.id);
        if (index !== -1) {
            const newCart = [...this.state.cart];
            newCart.splice(index, 1);
            this.setState({ cart: newCart });
        }
    };

    setSelectedProduct = (item, product) => {
        this.setState({
            storeItems: this.state.storeItems.map((i) => {
                if (i.id === item.id) {
                    i.currentProduct = product;
                }
                return i;
            }),
        });
    };

    render() {
        const {
            isLoading,
            cart,
            products,
            storeItems,
            showCart,
            success,
            message,
        } = this.state;

        if (isLoading === true) {
            return <PreloaderComponent />;
        }

        const cartPrice = cart.reduce((a, b) => a + b.price, 0);
        const cartCount = this.state.cart.length;
        return (
            <div>
                <HeaderComponent />
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        float: 'right',
                        justifyContent: 'flex-end',
                        marginRight: '20px',
                    }}
                >
                    <FontAwesomeIcon
                        icon={faShoppingCart}
                        size="lg"
                        onClick={() => this.setState({ showCart: true })}
                        style={{ cursor: 'pointer'}}
                    />
                    {cartCount > 0 && (
                        <div
                            style={{
                                position: 'relative',
                                top: '-10px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            {cartCount}
                        </div>
                    )}
                </div>
                <Modal
                    show={showCart}
                    onHide={() => this.setState({ showCart: false })}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ostoskori
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                        }}
                    >
                        {cart.length > 0 && <h2>Tuotteet</h2>}
                        {cart.map((product) => (
                            <div
                                className="row"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                key={
                                    'cart-item' +
                                    product.id.toString() +
                                    cart.indexOf(product).toString()
                                }
                            >
                                <div
                                    className="col"
                                    style={{ marginLeft: '30px' }}
                                >
                                    <h5>
                                        {product.name}: {product.price} €
                                    </h5>
                                    <p>{product.description}</p>
                                </div>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    size="lg"
                                    onClick={() => this.removeFromCart(product)}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'red',
                                        marginLeft: '10px',
                                    }}
                                />
                            </div>
                        ))}
                        {cart.length === 0 ? (
                            <p>Ostoskori on tyhjä</p>
                        ) : (
                            <h3>Yhteensä: {cartPrice} €</h3>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-success"
                            onClick={() => this.setState({ showCart: false })}
                            type="reset"
                        >
                            Takaisin
                        </button>
                        {cart.length > 0 && (
                            <button
                                className="btn btn-success"
                                onClick={() => this.handleBuy()}
                                type="submit"
                            >
                                Osta
                            </button>
                        )}
                    </Modal.Footer>
                </Modal>
                <MemberPayView
                    success={success}
                    message={message}
                    cart={cart}
                    storeItems={storeItems}
                    showCart={showCart}
                    products={products}
                    addToCart={this.addToCart}
                    setSelectedProduct={this.setSelectedProduct}
                />
                <FooterComponent/>
            </div>
        );
    }

    async componentDidMount() {
        try {
            const storeItemsResponse = await api.get('/store-item', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            const storeItems = storeItemsResponse.data;

            const products = await api.get(`/product`, {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            for (let item of storeItems) {
                const p = item.productIds.map((productId) => {
                    return products.data.find((p) => p.id === productId);
                });
                item.products = p;
                if (p.length > 0) {
                    item.currentProduct = p[0];
                }
            }

            console.log(storeItems);

            this.setState({
                ...this.state,
                isLoading: false,
                success: true,
                storeItems: storeItems,
                products: products.data,
            });
        } catch (e) {
            console.error(e);
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö serverille epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    }
}

export default MemberPayComponent;
