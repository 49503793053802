import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AnnouncementListView from './announcementListView';
import FooterComponent from '../../commons/footer/footerComponent';
import HeaderComponent from '../../commons/header/headerComponent';
import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';

class AnnouncementListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'draft',
            announcements: [],
            statuses: [],
            isLoading: true,
            success: true,
            message: null,
        };
    }

    render() {
        const { isLoading, announcements, status, message, success, statuses } =
            this.state;

        return (
            <div>
                <HeaderComponent />
                <AnnouncementListView
                    isLoading={isLoading}
                    announcements={announcements}
                    onNewAnnouncement={this.onNewAnnouncement}
                    onAnnouncementClick={this.openAnnouncementDetails}
                    setStatusFilter={this.setStatusFilter}
                    status={status}
                    message={message}
                    success={success}
                    statuses={statuses}
                />
                <FooterComponent />
            </div>
        );
    }

    openAnnouncementDetails = (id) => {
        this.props.history.push('/admin/announcement/' + id);
    };

    onNewAnnouncement = async () => {
        this.setState({
            ...this.state,
            ...{
                isLoading: true,
            },
        });
        try {
            let response = await api.post(
                '/announcement/new',
                {},
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            await this.setStatusFilter('draft');

            this.openAnnouncementDetails(response.data.id);
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    };

    setStatusFilter = async (status) => {
        this.setState({
            ...this.state,
            ...{
                status: status,
                isLoading: true,
            },
        });

        try {
            let response = await api.get('/announcement/list', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    status: status,
                },
            });

            let statusesResponse = await api.get('/announcement/statuses', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcements: response.data,
                    statuses: statusesResponse.data,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    };

    async componentDidMount() {
        this.setStatusFilter(this.state.status);
    }
}

export default withRouter(AnnouncementListComponent);
