import React from 'react';
import HeaderComponent from '../../commons/header/headerComponent';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import { Link } from 'react-router-dom';
import FooterComponent from '../../commons/footer/footerComponent';

const PayReturnView = ({ success, purchase, isLoading }) => {
    const showSuccess = success && purchase && purchase.status === 'paid';
    const showCancelled =
        !success || (success && purchase && purchase.status === 'cancelled');

    return (
        <div>
            <HeaderComponent />

            {(isLoading || (!showCancelled && !showSuccess)) && (
                <PreloaderComponent />
            )}

            {showSuccess && (
                <div className="container">
                    <h3>Maksutapahtuma onnistui</h3>
                    <p>
                        Olet suorittanut maksun onnistuneesti! Sinulle tulee
                        sähköpostiin kuitti onnistuneesta maksusta.
                    </p>

                    <p>
                        Jos ostit jäsenyyden, varmista jäsentietojen
                        päivittyneen oikein palaamalla etusivulle.
                    </p>

                    <p>
                        Muut tuotteet voit noutaa Kiltahuoneelta erikseen
                        ilmoitettuna ajankohtana kuittia vastaan.
                    </p>

                    <Link className="btn btn-success success" to={`/member`}>
                        Etusivulle
                    </Link>
                </div>
            )}

            {showCancelled && (
                <div className="container">
                    <h3>Maksutapahtuma keskeytyi</h3>
                    <p>
                        Et vienyt maksutapahtumaa loppuun asti, tai maksussa
                        tapahtui virhe. Jos sinua on veloitettu, ota yhteys
                        Digit ry hallitukseen.
                    </p>
                    <Link className="btn btn-success success" to={`/member`}>
                        Etusivulle
                    </Link>
                </div>
            )}
            <FooterComponent/>
        </div>
    );
};

export default PayReturnView;
