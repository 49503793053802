import React, { Component } from 'react';

import HeaderComponent from '../../commons/header/headerComponent';
import MemberDetailsView from './memberDetailsView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import MemberNotFoundComponent from '../../commons/memberNotFound/memberNotFoundComponent';

import { getCookie, getPrivileges } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';

class MemberDetailsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            privileges: getPrivileges(),
            isLoading: true,
            firstName: null,
            lastName: null,
            utuAccount: null,
            email: null,
            homeTown: null,
            membershipSince: null,
            membershipUntil: null,
            accepted: null,
            tyyMember: null,
            success: null,
            message: null,
            memberNotFound: false,
        };
    }

    render() {
        const {
            isLoading,
            firstName,
            lastName,
            utuAccount,
            email,
            homeTown,
            privileges,
            membershipSince,
            membershipUntil,
            accepted,
            tyyMember,
            memberNotFound,
        } = this.state;

        if (isLoading === true) {
            return <PreloaderComponent />;
        }

        if (memberNotFound) {
            return (
                <div>
                    <HeaderComponent /> <MemberNotFoundComponent />
                </div>
            );
        }

        return (
            <div>
                <HeaderComponent />
                <MemberDetailsView
                    isLoading={isLoading}
                    firstName={firstName}
                    lastName={lastName}
                    utuAccount={utuAccount}
                    email={email}
                    homeTown={homeTown}
                    privileges={privileges}
                    membershipSince={membershipSince}
                    membershipUntil={membershipUntil}
                    accepted={accepted}
                    tyyMember={tyyMember}
                />
                <FooterComponent />
            </div>
        );
    }

    async componentDidMount() {
        try {
            let response = await api.get('/member/self', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            this.setState({
                ...this.state,
                isLoading: false,
                success: true,
                ...response.data,
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiseen epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    }
}

export default MemberDetailsComponent;
