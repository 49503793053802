import React, { Component } from 'react';

import HeaderComponent from '../../commons/header/headerComponent';
import DatePicker from 'react-datepicker';
import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import './purchaseComponent.css';
import Collapsible from 'react-collapsible';

class PurchasesComponent extends Component {
    constructor(props) {
        super(props);

        const now = new Date();

        this.state = {
            isLoading: false,
            success: null,
            message: null,
            data: null,
            startDate: new Date(now.setFullYear(now.getFullYear() - 1)),
            endDate: new Date(),
        };
    }

    async fetchData() {
        this.setState({
            isLoading: true,
        });
        try {
            let body = {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            };
            let response = await api.post('/purchase/summary', body, {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            this.setState({
                ...this.state,
                isLoading: false,
                success: true,
                data: response.data,
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiseen epäonnistui.',
                    isLoading: false,
                },
            });
        }
    }

    render() {
        const { isLoading, data, startDate, endDate } = this.state;

        return (
            <div>
                <HeaderComponent />
                <div className="info-container">
                    <h3>Kirjanpidon apuvälineet</h3>
                    <p>
                        Tältä välilehdeltä saat helposti haettua digitin
                        verkkokaupan kautta tehdyt ostokset tietyltä
                        aikaväliltä.
                    </p>
                    <p>
                        Huomioithan että tämä listaus näyttää KAIKKI onnistuneet
                        maksutoimitukset. Mahdolliset palautukset tai muut
                        epänormaalista poikkeavat tilanteet tulee selvittää
                        erikseen.
                    </p>
                </div>
                <div className="actions">
                    <DatePicker
                        selected={startDate}
                        onChange={(value) =>
                            this.setState({
                                startDate: value,
                            })
                        }
                        dateFormat="dd.MM.yyyy"
                        className="form-control"
                        locale="fi"
                    />
                    <h1>-</h1>
                    <DatePicker
                        selected={endDate}
                        onChange={(value) =>
                            this.setState({
                                endDate: value,
                            })
                        }
                        dateFormat="dd.MM.yyyy"
                        className="form-control"
                        locale="fi"
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.fetchData()}
                    >
                        Hae
                    </button>
                </div>{' '}
                {isLoading && <PreloaderComponent />}
                {data &&
                    Object.keys(data).map((date) => {
                        const dateData = data[date];

                        const totalPrice = Object.keys(dateData)
                            .map((storeItem) => {
                                const storeItemData = dateData[storeItem];
                                return Object.keys(storeItemData)
                                    .map((p) => storeItemData[p].totalPrice)
                                    .reduce((a, b) => a + b, 0);
                            })
                            .reduce((a, b) => a + b, 0);

                        return (
                            <Collapsible
                                key={date}
                                trigger={
                                    <div className="collapsible-trigger-container">
                                        <h5>
                                            {date} <b>- {totalPrice} €</b>
                                        </h5>
                                        <h2>+</h2>
                                    </div>
                                }
                                classParentString="collapsible date-level"
                                transitionTime={100}
                            >
                                {Object.keys(data[date]).map((storeItem) => {
                                    const storeItemData = data[date][storeItem];

                                    const totalItems = Object.keys(
                                        storeItemData
                                    )
                                        .map((p) => storeItemData[p].amount)
                                        .reduce((a, b) => a + b, 0);
                                    const totalPrice = Object.keys(
                                        storeItemData
                                    )
                                        .map((p) => storeItemData[p].totalPrice)
                                        .reduce((a, b) => a + b, 0);
                                    return (
                                        <Collapsible
                                            key={storeItem}
                                            trigger={
                                                <div className="collapsible-trigger-container">
                                                    <h6>
                                                        <b>{totalItems} x</b>{' '}
                                                        {storeItem}{' '}
                                                        <b>- {totalPrice} €</b>
                                                    </h6>
                                                    <h2>+</h2>
                                                </div>
                                            }
                                            classParentString="collapsible store-item-level"
                                            transitionTime={100}
                                        >
                                            {Object.keys(
                                                data[date][storeItem]
                                            ).map((product) => {
                                                const prodData =
                                                    data[date][storeItem][
                                                        product
                                                    ];
                                                return (
                                                    <p key={product}>
                                                        <b>
                                                            {prodData.amount} x
                                                        </b>{' '}
                                                        {product}{' '}
                                                        <b>
                                                            -{' '}
                                                            {
                                                                prodData.totalPrice
                                                            }{' '}
                                                            €
                                                        </b>
                                                    </p>
                                                );
                                            })}
                                        </Collapsible>
                                    );
                                })}
                            </Collapsible>
                        );
                    })}
                <FooterComponent />
            </div>
        );
    }
}

export default PurchasesComponent;
