import React, { Component } from 'react';

import { Form, Modal } from 'react-bootstrap';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';

import HeaderComponent from '../../commons/header/headerComponent';
import MemberUpdateView from './memberUpdateView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import MemberNotFoundComponent from '../../commons/memberNotFound/memberNotFoundComponent';

import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';

class MemberUpdateComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            isLoading: true,
            firstName: null,
            lastName: null,
            utuAccount: null,
            email: null,
            homeTown: null,
            tyyMember: null,
            password: '',
            passwordAgain: '',
            success: null,
            message: null,
            showModal: false,
            memberNotFound: false,
            passwordConfirmation: '',
            showPasswordConfirmationModal: false,
        };
    }

    submitMainForm = async (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            showPasswordConfirmationModal: true,
        });
    };

    cancelPasswordConfirmation = async () => {
        this.setState({
            ...this.state,
            showPasswordConfirmationModal: false,
        });
    };

    submitPasswordConfirmation = async (event) => {
        event.preventDefault();
        console.log('submitting password confirmation');
        this.setState({
            ...this.state,
            showPasswordConfirmationModal: false,
        });
        let success = false;
        try {
            const detailsResponse = await api.get('/member/self', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });
            const data = {
                email: detailsResponse.data.email,
                password: this.state.passwordConfirmation,
            };
            const response = await api.post('/login', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            success = response.data.token;
        } catch (error) {
            console.error(error);
        }
        if (success) {
            await this.handleUpdateMember();
        } else {
            this.setState({
                ...this.state,
                success: false,
                message:
                    'Syöttämäsi salasana on väärä, joten tietoja ei päivitetty.',
            });
        }
    };

    handleUpdateMember = async () => {
        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            utuAccount: this.state.utuAccount,
            email: this.state.email,
            homeTown: this.state.homeTown,
            tyyMember: this.state.tyyMember,
            password: this.state.password,
            passwordAgain: this.state.passwordAgain,
            id: this.state.id,
        };

        try {
            const response = await api.put('/member/details', data, {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });
            let modal = false;

            if (response.data.success && response.data.message) {
                modal = true;
            }
            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: response.data.success,
                    message: response.data.message,
                    showModal: modal,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen päivittämiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    matchPassword = (value) => {
        if (value || this.state.password) {
            return value === this.state.password;
        } else {
            return true;
        }
    };

    handlePasswordReset = async () => {
        try {
            const response = await api.post(
                '/forgot/authenticated',
                { email: this.state.email },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            let modal = false;
            if (response.data.success && response.data.message) {
                modal = true;
            }
            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: response.data.success,
                    message: response.data.message,
                    showModal: modal,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Salasanan nollaus epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    render() {
        let modalClose = () => this.setState({ showModal: false });
        const {
            isLoading,
            firstName,
            lastName,
            utuAccount,
            email,
            homeTown,
            tyyMember,
            success,
            message,
            showModal,
            memberNotFound,
        } = this.state;

        if (isLoading === true) {
            return <PreloaderComponent />;
        }

        if (memberNotFound) {
            return (
                <div>
                    <HeaderComponent /> <MemberNotFoundComponent />
                </div>
            );
        }

        return (
            <div>
                <HeaderComponent />
                <Modal
                    show={this.state.showPasswordConfirmationModal}
                    onHide={this.cancelPasswordConfirmation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <ValidationForm onSubmit={this.submitPasswordConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Vahvistus
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    Syötä tämänhetkinen salasanasi
                                    vahvistaaksesi muutokset
                                </Form.Label>
                                <TextInput
                                    type="password"
                                    name="passwordConfirmation"
                                    id="passwordConfirmation"
                                    defaultValue={this.passwordConfirmation}
                                    errorMessage={{
                                        required:
                                            'Salasanan syöttäminen on pakollista.',
                                    }}
                                    required
                                    onChange={this.handleInputChange}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-success">Päivitä</button>
                            <button
                                className="btn btn-success"
                                onClick={this.cancelPasswordConfirmation}
                                type="reset"
                            >
                                Takaisin
                            </button>
                        </Modal.Footer>
                    </ValidationForm>
                </Modal>
                <Modal
                    show={showModal}
                    onHide={modalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ilmoitus
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{message}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success" onClick={()=>this.setState({
                            ...this.state,
                            showModal: false,
                        })}>
                            Takaisin
                        </button>
                    </Modal.Footer>
                </Modal>
                <MemberUpdateView
                    isLoading={isLoading}
                    firstName={firstName}
                    lastName={lastName}
                    utuAccount={utuAccount}
                    email={email}
                    homeTown={homeTown}
                    tyyMember={tyyMember}
                    handleInputChange={this.handleInputChange}
                    success={success}
                    message={message}
                    submitMainForm={this.submitMainForm}
                    matchPassword={this.matchPassword}
                    handlePasswordReset={this.handlePasswordReset}
                />
                <FooterComponent />
            </div>
        );
    }

    async componentDidMount() {
        try {
            let response = await api.get('/member/self', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            this.setState({
                ...this.state,
                isLoading: false,
                success: true,
                ...response.data,
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiseen epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    }
}

export default MemberUpdateComponent;
