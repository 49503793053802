import React from 'react';
import { Button, Form } from 'react-bootstrap';
import './memberPayView.css';

const MemberPayView = ({ storeItems, addToCart, setSelectedProduct }) => (
    <div className="container">
        <h3>Digitin kauppa</h3>
        <p>
            Tervetuloa Digitin kauppaan! Täältä voit ostaa Digitin jäsenyyden
            tai muita Digitin tuotteita.
        </p>
        <p>
            Saat ostotapahtumista aina kuitin siihen sähköpostiin, jonka olet
            jäsenrekisteriin itsellesi määrittänyt. Jos et saa kuittia, tarkista
            roskapostikansio.
        </p>
        <p>
            Jäsenmaksut kasvattavat automaattisesti jäsenyytesi
            voimassaoloaikaa, joten varmista jäsentietojen päivittyneen oikein
            palaamalla etusivulle onnistuneen maksutapahtuman jälkeen.
        </p>
        <p>
            Fyysisiä tuotteita ei postiteta, eli ne voi noutaa erikseen
            sovittuna ajankohtana Kiltahuoneelta kuittia vastaan.
        </p>
        <div
            className="row"
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            }}
        >
            {storeItems.map((item) => {
                return (
                    <div key={'shop-item' + item.id} className="shop-item">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (item.currentProduct) {
                                    addToCart(item.currentProduct);
                                }
                            }}
                        >
                            <Form.Group>
                                <Form.Label>{item.name}</Form.Label>
                                <Form.Text>{item.description}</Form.Text>

                                <Form.Row className="d-flex justify-content-center">
                                    {item.products.length > 1 && (
                                        <Form.Control
                                            as="select"
                                            value={
                                                item.currentProduct
                                                    ? item.currentProduct.id
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                const productId =
                                                    e.target.value;
                                                const selectedProduct =
                                                    item.products.find(
                                                        (product) =>
                                                            product.id ===
                                                            productId
                                                    );

                                                setSelectedProduct(
                                                    item,
                                                    selectedProduct
                                                );
                                            }}
                                        >
                                            {item.products.map((product) => (
                                                <option
                                                    key={product.id}
                                                    value={product.id}
                                                >
                                                    {product.name} (
                                                    {product.price} €)
                                                </option>
                                            ))}
                                        </Form.Control>
                                    )}
                                    {item.currentProduct && (
                                        <Button type="submit" variant="success">
                                            Lisää ostoskoriin{' '}
                                            {item.currentProduct.price} €
                                        </Button>
                                    )}
                                </Form.Row>
                            </Form.Group>
                        </Form>
                    </div>
                );
            })}
        </div>
    </div>
);

export default MemberPayView;
