import React from 'react';

import { Alert, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fi from 'date-fns/locale/fi';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import validator from 'validator';

import '../../assets/validatedCheckbox.css';
import { getPrivilegeString } from '../../../utils/privileges';

registerLocale('fi', fi);

const MemberUpdateAdminView = ({
    firstName,
    lastName,
    utuAccount,
    email,
    homeTown,
    tyyMember,
    privileges,
    membershipSince,
    membershipUntil,
    accepted,
    handleUpdateAdmin,
    handleInputChange,
    handleMembershipStartsChange,
    handleMembershipEndsChange,
    handlePrivilegeStartChange,
    handlePrivilegeEndChange,
    handlePrivilegeChange,
    handleRemovePrivilege,
    handleAddPrivilege,
    success,
    message,
    memberID,
    matchPassword,
    handlePasswordReset,
}) => (
    <div className="container">
        <ValidationForm
            onSubmit={(e) => {
                e.preventDefault();
                handleUpdateAdmin(e);
            }}
        >
            <Form.Group>
                <Form.Label>Etunimi</Form.Label>
                <TextInput
                    name="firstName"
                    id="firstName"
                    defaultValue={firstName}
                    errorMessage={{
                        required: 'Etunimi on pakollinen.',
                        pattern: 'Tarkista etunimi.',
                    }}
                    required
                    pattern="[a-zA-Z\u00c0-\u017e- ]{2,40}$"
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Sukunimi</Form.Label>
                <TextInput
                    name="lastName"
                    id="lastName"
                    defaultValue={lastName}
                    errorMessage={{
                        required: 'Sukunimi on pakollinen.',
                        pattern: 'Tarkista sukunimi.',
                    }}
                    required
                    pattern="[a-zA-Z\u00c0-\u017e- ]{2,25}$"
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>UTU-tunnus (ilman @utu.fi)</Form.Label>
                <TextInput
                    name="utuAccount"
                    id="utuAccount"
                    defaultValue={utuAccount}
                    errorMessage={{
                        required: 'UTU-tunnus on pakollinen.',
                        pattern: 'Tarkista UTU-tunnus.',
                    }}
                    pattern="[a-öA-Ö.]{2,30}$"
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Sähköposti</Form.Label>
                <TextInput
                    name="email"
                    defaultValue={email}
                    id="email"
                    errorMessage={{
                        required: 'Sähköpostiosoite on pakollinen.',
                        validator: 'Tarkista sähköpostiosoite.',
                    }}
                    validator={validator.isEmail}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Kotikunta</Form.Label>
                <TextInput
                    name="homeTown"
                    id="homeTown"
                    defaultValue={homeTown}
                    errorMessage={{
                        required: 'Kotikunta on pakollinen.',
                        pattern: 'Tarkista kotikunta.',
                    }}
                    required
                    pattern="[a-zA-Z\u00c0-\u017e- ]{2,25}$"
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Check
                    type="checkbox"
                    checked={tyyMember}
                    onChange={handleInputChange}
                    name="tyyMember"
                    label="TYYn jäsen"
                />
            </Form.Group>
            <Form.Group>
                <Form.Check
                    type="checkbox"
                    checked={accepted}
                    onChange={handleInputChange}
                    name="accepted"
                    label="Hyväksytty jäseneksi"
                />
            </Form.Group>
            <div className="row">
                <div className="col">
                    <Form.Label className="d-block">Jäsenyys alkaa</Form.Label>
                    <DatePicker
                        selected={
                            membershipSince ? new Date(membershipSince) : null
                        }
                        onChange={handleMembershipStartsChange}
                        dateFormat="dd.MM.yyyy"
                        className="form-control"
                        locale="fi"
                    />
                </div>
                <div className="col">
                    <Form.Label className="d-block">
                        Jäsenyys päättyy
                    </Form.Label>
                    <DatePicker
                        selected={
                            membershipUntil ? new Date(membershipUntil) : null
                        }
                        onChange={handleMembershipEndsChange}
                        dateFormat="dd.MM.yyyy"
                        className="form-control"
                        locale="fi"
                    />
                </div>
            </div>
            <hr />
            <h6>Roolit</h6>
            {privileges.map((privilege, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: '#ececec',
                        padding: '15px',
                        margin: '10px',
                        borderRadius: '5px',
                    }}
                >
                    <Form.Group>
                        <Form.Label>Rooli</Form.Label>
                        <Form.Control
                            as="select"
                            name={`privileges[${index}].role`}
                            value={privilege.role}
                            onChange={(e) =>
                                handlePrivilegeChange(index, e.target.value)
                            }
                        >
                            <option value="kiltisban">
                                {getPrivilegeString(['kiltisban'])}
                            </option>
                            <option value="admin">
                                {getPrivilegeString(['admin'])}
                            </option>
                            <option value="chairman">
                                {getPrivilegeString(['chairman'])}
                            </option>
                            <option value="vicechairman">
                                {getPrivilegeString(['vicechairman'])}
                            </option>
                            <option value="boardmember">
                                {getPrivilegeString(['boardmember'])}
                            </option>
                            <option value="functionary">
                                {getPrivilegeString(['functionary'])}
                            </option>
                            <option value="tutor">
                                {getPrivilegeString(['tutor'])}
                            </option>
                            <option value="bachelorfresher">
                                {getPrivilegeString(['bachelorfresher'])}
                            </option>
                            <option value="masterfresher">
                                {getPrivilegeString(['masterfresher'])}
                            </option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Alkaa</Form.Label>
                        <DatePicker
                            selected={
                                privilege.startDate
                                    ? new Date(privilege.startDate)
                                    : null
                            }
                            onChange={(value) =>
                                handlePrivilegeStartChange(value, index)
                            }
                            dateFormat="dd.MM.yyyy"
                            className="form-control"
                            locale="fi"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Päättyy</Form.Label>
                        <DatePicker
                            selected={
                                privilege.endDate
                                    ? new Date(privilege.endDate)
                                    : null
                            }
                            onChange={(value) =>
                                handlePrivilegeEndChange(value, index)
                            }
                            dateFormat="dd.MM.yyyy"
                            className="form-control"
                            locale="fi"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Row>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleRemovePrivilege(index)}
                            >
                                Poista
                            </button>
                        </Form.Row>
                    </Form.Group>
                </div>
            ))}
            <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddPrivilege}
            >
                Lisää rooli
            </button>
            <hr />
            {message && !success ? (
                <Alert variant={!success ? 'danger' : 'success'}>
                    {message}
                </Alert>
            ) : null}
            <div className="buttons-row">
                 <Link
                    className="btn btn-secondary secondary"
                    to={`/member/details/${memberID}`}
                >
                    Takaisin
                </Link>
                <div className='tight-row'>
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={()=>handlePasswordReset()}
                >
                    Nollaa salasana
                </button>
                <button className="btn btn-success">Päivitä</button>
                </div>
            </div>
        </ValidationForm>
    </div>
);

export default MemberUpdateAdminView;
