import React from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';

import { hasPrivilege } from '../../../utils/cookies';
import { getPrivilegeString } from '../../../utils/privileges';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './memberList.css';

const { SearchBar } = Search;

const CSVButtons = (props) => {
    return (
        <div className="row">
            <button
                className="btn btn-success"
                onClick={() => props.onImport()}
                disabled={props.isLoading || props.handlingCsv}
            >
                Tuo CSV:stä
            </button>
        </div>
    );
};

function membershipUntilFormatter(cell, row) {
    const currentDate = new Date();
    if (row.membershipUntil) {
        if (new Date(row.membershipUntil) <= currentDate) {
            return (
                <div>
                    {Moment(row.membershipUntil).format('D.M.YYYY')}{' '}
                    <FontAwesomeIcon icon="exclamation-triangle" color="red" />
                </div>
            );
        } else {
            return <div>{Moment(row.membershipUntil).format('D.M.YYYY')}</div>;
        }
    } else {
        return (
            <div>
                <FontAwesomeIcon icon="times" color="red" />
            </div>
        );
    }
}


const columns = [
    {
        dataField: 'firstName',
        text: 'Etunimi',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        style: { textTransform: 'capitalize' },
        headerStyle: {
            width: '150px',
        },
    },
    {
        dataField: 'lastName',
        text: 'Sukunimi',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '150px',
        },
    },
    {
        dataField: 'utuAccount',
        text: 'UTU',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '100px',
        },
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true,
        headerStyle: {
            width: '230px',
        },
        align: 'center',
        headerAlign: 'center',
    },
    {
        dataField: 'homeTown',
        text: 'Kotikunta',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '130px',
        },
    },
    {
        dataField: 'tyyMember',
        text: 'TYYn jäsen',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '130px',
        },
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {row.tyyMember ? (
                    <p>
                        <FontAwesomeIcon icon="check" color="green" />
                    </p>
                ) : (
                    <p>
                        <FontAwesomeIcon icon="times" color="red" />
                    </p>
                )}
            </div>
        ),
    },
    {
        dataField: 'accepted',
        text: 'Hyväksytty',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '130px',
        },
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {row.accepted ? (
                    <p>
                        <FontAwesomeIcon icon="check" color="green" />
                    </p>
                ) : (
                    <p>
                        <FontAwesomeIcon icon="times" color="red" />
                    </p>
                )}
            </div>
        ),
    },
    {
        dataField: 'membershipSince',
        text: 'Alkanut',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '130px',
        },
        formatter: (cell, row, rowIndex, extraData) => (
            <div>
                {row.membershipSince ? (
                    Moment(row.membershipSince).format('D.M.YYYY')
                ) : (
                    <FontAwesomeIcon icon="times" color="red" />
                )}
            </div>
        ),
    },
    {
        dataField: 'membershipUntil',
        text: 'Päättyy',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            width: '130px',
        },
        formatter: membershipUntilFormatter,
    },
    {
        dataField: 'privileges',
        headerStyle: {
            width: '200px',
        },
        text: 'Roolit',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        formatter: (privileges) => getPrivilegeString(privileges),
    },
    // {
    //     dataField: 'membershipValid',
    //     text: 'Jäsenyys voimassa',
    //     sort: true,
    //     align: 'center',
    //     headerAlign: 'center',
    //     headerStyle: {
    //         width: '180px',
    //     },
    //     formatter: (cell, row, rowIndex, extraData) => (
    //         <div>
    //             {row.membershipValid ? (
    //                 <p>
    //                     <FontAwesomeIcon icon="check" color="green" />
    //                 </p>
    //             ) : (
    //                 <p>
    //                     <FontAwesomeIcon icon="times" color="red" />
    //                 </p>
    //             )}
    //         </div>
    //     ),
    // },
    // {
    //     dataField: 'entitledToCap',
    //     text: 'Fuksipassi pakolliset',
    //     sort: true,
    //     align: 'center',
    //     headerAlign: 'center',
    //     headerStyle: {
    //         width: '180px',
    //     },
    //     formatter: (cell, row, rowIndex, extraData) => (
    //         <div>
    //             {row.entitledToCap ? (
    //                 <p>
    //                     <FontAwesomeIcon icon="check" color="green" />
    //                 </p>
    //             ) : (
    //                 <p>
    //                     <FontAwesomeIcon icon="times" color="red" />
    //                 </p>
    //             )}
    //         </div>
    //     ),
    // },
    // {
    //     dataField: 'entitledToPatch',
    //     text: 'Fuksipassi harraste',
    //     sort: true,
    //     align: 'center',
    //     headerAlign: 'center',
    //     headerStyle: {
    //         width: '180px',
    //     },
    //     formatter: (cell, row, rowIndex, extraData) => (
    //         <div>
    //             {row.entitledToPatch ? (
    //                 <p>
    //                     <FontAwesomeIcon icon="check" color="green" />
    //                 </p>
    //             ) : (
    //                 <p>
    //                     <FontAwesomeIcon icon="times" color="red" />
    //                 </p>
    //             )}
    //         </div>
    //     ),
    // },
];

const defaultSorted = [
    {
        dataField: 'firstName',
        order: 'desc',
    },
];


const MemberListView = (props) => {
    if (props.list === undefined) {
        return null;
    }

    if (props.list.length === 0) {
        return <div className="members-none">Jäseniä ei löytynyt.</div>;
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            props.handleClick(row);
        },
    };
    const selectRow = {
        mode: 'checkbox',
        onSelect(row, isSelect, rowIndex, e) {
            props.updateSelectedMembers(row, isSelect);
        },
        onSelectAll(isSelect, rows, e) {
            props.updateSelectedMembers(rows, isSelect);
        },
    };

    function onImport() {
        props.onImport();
    }

    function onMembersPurgeModalOpen() {
        props.onMembersPurgeModalOpen();
    }

    function onFunctionsModalOpen() {
        props.onFunctionsModalOpen();
    }
    const selectedMembersCount = props.selectedMembersCount();



    let isLoading = props.isLoading;
    let handlingCsv = props.handlingCsv;

    return (
        <ToolkitProvider
            bootstrap4
            keyField="email"
            data={props.list}
            columns={columns}
            defaultSorted={defaultSorted}
            search
            exportCSV={{
                fileName: 'members.csv',
                separator: ',',
                ignoreHeader: true,
            }}
        >
            {(props) => (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <SearchBar
                                {...props.searchProps}
                                placeholder="Haku"
                            />
                        </div>

                        {hasPrivilege('boardmember') ||
                        hasPrivilege('chairman') ||
                        hasPrivilege('vicechairman') ||
                        hasPrivilege('admin') ? (
                            <div
                                className="col"
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >

                                <Link
                                    className="addNew float-right btn btn-success"
                                    to="/admin/new"
                                >
                                    Lisää jäsen
                                </Link>
                                <Button
                                    className={`functionButton float-right btn btn-succes ${selectedMembersCount > 0 ? '' : 'notClickable'}`}
                                    onClick={() => onFunctionsModalOpen()}
                                    disabled={selectedMembersCount === 0}
                                    >
                                    Toimintonappi
                                </Button>
                                <Button
                                    className={`float-right btn btn-succes`}
                                    onClick={() => onMembersPurgeModalOpen()}>
                                    Jäsenien puhdistus
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    <hr />
                    <div className="memberlist">
                        <BootstrapTable
                            {...props.baseProps}
                            striped
                            hover
                            selectRow={selectRow}
                            classes="memberlistTable"
                            rowEvents={rowEvents}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="csvButton">
                                <CSVButtons
                                    {...props.csvProps}
                                    onImport={onImport}
                                    isLoading={isLoading}
                                    handlingCsv={handlingCsv}
                                ></CSVButtons>
                            </div>
                        </div>
                        <div className="col">
                            <div className="membercount float-right">
                                <p>
                                    Jäseniä yhteensä{' '}
                                    {Object.keys(props.baseProps.data).length}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ToolkitProvider>
    );
};

export default MemberListView;
