import React, { Component } from 'react';

import { Modal, Button } from 'react-bootstrap';
import HeaderComponent from '../../commons/header/headerComponent';
import MemberDetailsAdminView from './memberDetailsAdminView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import MemberNotFoundComponent from '../../commons/memberNotFound/memberNotFoundComponent';

import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';

class MemberDetailsAdminComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            memberID: this.props.match.params.id,
            firstName: null,
            lastName: null,
            utuAccount: null,
            email: null,
            homeTown: null,
            tyyMember: null,
            privileges: null,
            entitledToCap: null,
            entitledToPatch: null,
            membershipSince: null,
            membershipUntil: null,
            createdAt: null,
            accepted: null,
            password: null,
            passwordAgain: null,
            success: null,
            message: null,
            warning: false,
            dialogMessage: '',
            memberNotFound: false,
        };
    }

    onHandleRemove = () => {
        this.setState({
            warning: true,
            dialogMessage: `Haluatko varmasti poistaa jäsenen ${this.state.firstName} ${this.state.lastName}?`,
        });
    };

    handleRemove = async (event) => {
        const response = event.target.innerHTML.toLowerCase();

        if (response === 'kyllä') {
            const data = {
                memberID: this.state.memberID,
                email: this.state.email,
            };
            try {
                const response = await api.post('/admin/remove', data, {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                });

                this.setState({
                    ...this.state,
                    ...{
                        isLoading: false,
                        success: response.data.success,
                        message: response.data.message,
                    },
                });
            } catch (e) {
                this.setState({
                    ...this.state,
                    ...{
                        success: false,
                        message:
                            e?.response?.data?.message ??
                            'Pyyntö jäsenen poistolle epäonnistui.',
                        isLoading: false,
                    },
                });
            }
        }

        this.setState({
            warning: false,
            dialogMessage: '',
        });
    };

    render() {
        let modalClose = () => this.setState({ warning: false });
        const {
            isLoading,
            firstName,
            lastName,
            utuAccount,
            email,
            homeTown,
            tyyMember,
            privileges,
            entitledToCap,
            entitledToPatch,
            membershipSince,
            membershipUntil,
            createdAt,
            accepted,
            success,
            message,
            memberID,
            warning,
            dialogMessage,
            memberNotFound,
        } = this.state;

        if (isLoading) {
            return <PreloaderComponent />;
        }

        if (memberNotFound) {
            return (
                <div>
                    <HeaderComponent /> <MemberNotFoundComponent /> <FooterComponent />
                </div>
            );
        }

        return (
            <div>
                <HeaderComponent />
                <Modal
                    show={warning}
                    onHide={modalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Varoitus
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{dialogMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.handleRemove}>
                            Kyllä
                        </Button>
                        <Button variant="success" onClick={modalClose}>
                            Ei
                        </Button>
                    </Modal.Footer>
                </Modal>
                <MemberDetailsAdminView
                    isLoading={isLoading}
                    firstName={firstName}
                    lastName={lastName}
                    utuAccount={utuAccount}
                    email={email}
                    homeTown={homeTown}
                    tyyMember={tyyMember}
                    privileges={privileges}
                    entitledToCap={entitledToCap}
                    entitledToPatch={entitledToPatch}
                    membershipSince={membershipSince}
                    membershipUntil={membershipUntil}
                    success={success}
                    message={message}
                    memberID={memberID}
                    handleRemove={this.onHandleRemove.bind(this)}
                    createdAt={createdAt}
                    accepted={accepted}
                />
                <FooterComponent />
            </div>
        );
    }
    async componentDidMount() {
        try {
            let profileData = await api.get('/member/details', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    memberID: this.state.memberID,
                },
            });
            const capData = await api.get('/fresherpass/validate/cap', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    memberID: this.state.memberID,
                },
            });

            const patchData = await api.get('/fresherpass/validate/patch', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },

                params: {
                    memberID: this.state.memberID,
                },
            });

            this.setState({
                ...this.state,
                isLoading: false,
                success: true,
                entitledToCap: capData.data,
                entitledToPatch: patchData.data,
                ...profileData.data,
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiseen epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    }
}

export default MemberDetailsAdminComponent;
