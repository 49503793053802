import React, { Component } from 'react';

import digitlogo from '../../assets/digit.webp';

import './preloaderComponent.css';

class PreloaderComponent extends Component {
    render() {
        if (this.props.size === 'small') {
            return (
                <img
                    alt="Digit preloader"
                    className="mx-auto d-block preloaderSmall"
                    src={digitlogo}
                />
            );
        } else {
            return (
                <img
                    alt="Digit preloader"
                    className="mx-auto d-block preloader"
                    src={digitlogo}
                />
            );
        }
    }
}

export default PreloaderComponent;
