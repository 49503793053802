import React from 'react';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import Moment from 'moment';
import './announcementList.css';
import { announcementStatusString } from '../../../utils/announcements';
import { Alert, Form } from 'react-bootstrap';

const AnnouncementListView = ({
    announcements,
    onNewAnnouncement,
    isLoading,
    onAnnouncementClick,
    setStatusFilter,
    status,
    message,
    success,
    statuses,
}) => {
    function getStatusText(announcement) {
        const dateString = announcement.publishedAt
            ? Moment(announcement.publishedAt).format('D.M.YYYY')
            : '';

        return announcementStatusString(announcement.status) + ' ' + dateString;
    }

    return (
        <div className="announcements">
            <div className="title-row">
                <h2>Tiedotteet</h2>
                <button
                    className="btn btn-success"
                    onClick={onNewAnnouncement}
                    type="reset"
                >
                    Uusi tiedote
                </button>
            </div>
            <Form className="filter">
                <Form.Group>
                    <Form.Label>Suodata</Form.Label>
                    <Form.Control
                        as="select"
                        name="status"
                        value={status}
                        onChange={(e) => {
                            setStatusFilter(e.target.value);
                        }}
                    >
                        {statuses.map((status) => (
                            <option value={status} key={status}>
                                {announcementStatusString(status)}
                            </option>
                        ))}
                        <option value={''}>
                            {announcementStatusString(null)}
                        </option>
                    </Form.Control>
                </Form.Group>
            </Form>
            {message && !success ? (
                <Alert variant={!success ? 'danger' : 'success'}>
                    {message}
                </Alert>
            ) : null}
            {isLoading && <PreloaderComponent />}
            {!isLoading && announcements && announcements.length === 0 && (
                <p>Hakukriteerejä vastaavia tiedotteita ei löytynyt.</p>
            )}
            {!isLoading &&
                announcements &&
                announcements.map((announcement) => {
                    return (
                        <div
                            key={announcement.id}
                            className="single-announcement"
                            onClick={() => onAnnouncementClick(announcement.id)}
                        >
                            <div className="single-announcement-details">
                                <h5>
                                    {announcement.titleFI} /{' '}
                                    {announcement.titleEN}
                                </h5>
                                <p>
                                    Luotu{' '}
                                    {Moment(announcement.createdAt).format(
                                        'D.M.YYYY'
                                    )}
                                </p>
                            </div>
                            <p
                                className={
                                    'single-announcement-status ' +
                                    announcement.status
                                }
                            >
                                {getStatusText(announcement)}
                            </p>
                        </div>
                    );
                })}
        </div>
    );
};

export default AnnouncementListView;
