import React, { Component } from 'react';

import MemberUpdateAdminView from './memberUpdateAdminView';
import HeaderComponent from '../../commons/header/headerComponent';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import MemberNotFoundComponent from '../../commons/memberNotFound/memberNotFoundComponent';

import { Modal } from 'react-bootstrap';

import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import FooterComponent from '../../commons/footer/footerComponent';

class MemberUpdateAdminComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            memberID: this.props.match.params.id,
            firstName: null,
            lastName: null,
            utuAccount: null,
            email: null,
            homeTown: null,
            tyyMember: null,
            privileges: null,
            membershipSince: null,
            membershipUntil: null,
            accepted: null,
            password: '',
            passwordAgain: '',
            success: null,
            message: null,
            showModal: false,
            memberNotFound: false,
        };
        this.handleMembershipStartsChange =
            this.handleMembershipStartsChange.bind(this);
        this.handleMembershipEndsChange =
            this.handleMembershipEndsChange.bind(this);
    }

    handleUpdateAdmin = async (event) => {
        event.preventDefault();
        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            utuAccount: this.state.utuAccount,
            email: this.state.email,
            homeTown: this.state.homeTown,
            tyyMember: this.state.tyyMember,
            membershipSince: this.state.membershipSince,
            membershipUntil: this.state.membershipUntil,
            password: this.state.password,
            passwordAgain: this.state.passwordAgain,
            accepted: this.state.accepted,
            memberID: this.state.memberID,
        };

        try {
            const response = await api.put('/admin/update', data, {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            const privileges = await api.post(
                '/admin/privileges',
                { privileges: this.state.privileges },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                    params: {
                        memberID: this.state.memberID,
                        all: true,
                    },
                }
            );
            let modal = false;
            if (response.data.success && response.data.message) {
                modal = true;
            }
            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: response.data.success,
                    message: response.data.message,
                    showModal: modal,
                    privileges: privileges.data ?? this.state.privileges,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen päivittämiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    };

    handlePasswordReset = async () => {
        try {
            const response = await api.post(
                '/forgot/authenticated',
                { email: this.state.email },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            let modal = false;
            if (response.data.success && response.data.message) {
                modal = true;
            }
            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: response.data.success,
                    message: response.data.message,
                    showModal: modal,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Salasanan nollaus epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleMembershipStartsChange = (date) => {
        this.setState({
            membershipSince: date,
        });
    };

    handleMembershipEndsChange = (date) => {
        this.setState({
            membershipUntil: date,
        });
    };

    handlePrivilegeStartChange = (date, index) => {
        this.setState({
            privileges: this.state.privileges.map((privilege, i) => {
                if (i === index) {
                    privilege.startDate = date;
                }
                return privilege;
            }),
        });
    };
    handlePrivilegeEndChange = (date, index) => {
        this.setState({
            privileges: this.state.privileges.map((privilege, i) => {
                if (i === index) {
                    privilege.endDate = date;
                }
                return privilege;
            }),
        });
    };

    handlePrivilegeChange = (index, value) => {
        this.setState({
            privileges: this.state.privileges.map((p, i) => {
                if (i === index) {
                    p.role = value;
                }
                return p;
            }),
        });
    };

    handleRemovePrivilege = (index) => {
        this.setState({
            privileges: this.state.privileges.filter((p, i) => i !== index),
        });
    };

    handleAddPrivilege = () => {
        this.setState({
            privileges: [
                ...this.state.privileges,
                {
                    memberId: this.state.memberID,
                    startDate: Date(),
                    endDate: Date(),
                    role: 'fresher',
                },
            ],
        });
    };

    matchPassword = (value) => {
        if (value || this.state.password) {
            return value === this.state.password;
        } else {
            return true;
        }
    };

    render() {
        let modalClose = () => this.setState({ showModal: false });
        const {
            isLoading,
            firstName,
            lastName,
            utuAccount,
            email,
            homeTown,
            tyyMember,
            privileges,
            membershipSince,
            membershipUntil,
            accepted,
            success,
            message,
            memberID,
            showModal,
            memberNotFound,
        } = this.state;

        if (isLoading === true) {
            return <PreloaderComponent />;
        }

        if (memberNotFound) {
            return (
                <div>
                    <HeaderComponent /> <MemberNotFoundComponent />{' '}
                    <FooterComponent />
                </div>
            );
        }

        return (
            <div>
                <HeaderComponent />
                <Modal
                    show={showModal}
                    onHide={modalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ilmoitus
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{message}</p>
                    </Modal.Body>
                    <Modal.Footer>
                       <button className="btn btn-success" onClick={()=>this.setState({
                            ...this.state,
                            showModal: false,
                        })}>
                            Takaisin
                        </button>
                    </Modal.Footer>
                </Modal>
                <MemberUpdateAdminView
                    isLoading={isLoading}
                    firstName={firstName}
                    lastName={lastName}
                    utuAccount={utuAccount}
                    email={email}
                    homeTown={homeTown}
                    tyyMember={tyyMember}
                    privileges={privileges}
                    membershipSince={membershipSince}
                    membershipUntil={membershipUntil}
                    accepted={accepted}
                    handleUpdateAdmin={this.handleUpdateAdmin}
                    handleInputChange={this.handleInputChange}
                    handleMembershipStartsChange={
                        this.handleMembershipStartsChange
                    }
                    handleMembershipEndsChange={this.handleMembershipEndsChange}
                    handlePrivilegeStartChange={this.handlePrivilegeStartChange}
                    handlePrivilegeEndChange={this.handlePrivilegeEndChange}
                    handleAddPrivilege={this.handleAddPrivilege}
                    handlePrivilegeChange={this.handlePrivilegeChange}
                    handleRemovePrivilege={this.handleRemovePrivilege}
                    matchPassword={this.matchPassword}
                    success={success}
                    message={message}
                    memberID={memberID}
                    handlePasswordReset={this.handlePasswordReset}
                />
                <FooterComponent />
            </div>
        );
    }

    async componentDidMount() {
        try {
            let profileData = await api.get('/admin/profile', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    memberID: this.state.memberID,
                },
            });

            const privileges = await api.get('/member/privileges', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    memberID: this.state.memberID,
                    all: true,
                },
            });

            this.setState({
                ...this.state,
                isLoading: false,
                success: true,
                ...profileData.data,
                privileges: privileges.data ?? [],
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiselle epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    }
}

export default MemberUpdateAdminComponent;
