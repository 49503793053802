import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Alert } from 'react-bootstrap';

import MemberListView from './memberListView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import MemberNotFoundComponent from '../../commons/memberNotFound/memberNotFoundComponent';

import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';

import Moment from 'moment';

registerLocale('fi', fi);

const csv = require('fast-csv');
class MemberListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            selectedMembers: [],
            handlingCsv: false,
            members: null,
            success: null,
            message: undefined,
            memberNotFound: false,

            selectedAcceptanceStatus: false,
            selectedFunction: null,
            allowFunctionsExecution: false,
            functionsExecutionSuccess: null,
            functionsExecutionMessage: '',
            showFunctionsModal: false,

            showMembersPurgeModal: false,
            membersPurgeDate: null,
            membersPurgeResultMessage: '',
            membersPurgeInProgress: false,
            membersPurgeSuccess: false,
            membersPurgeCurrentCount: null,
            membersPurgeMaxCount: null,

            showPageChangeAlert: false,
            pageChangeLink: null,

            showImportModal: false,
            importModalMessage: null,
            importModalMaxCount: null,
            importModalCurrentCount: null,
            importModalTimeEstimationSeconds: null,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handlePurgeDateChange = this.handlePurgeDateChange.bind(this);
    }

    handleClick = (row) => {
        if (this.getSelectedMembersCount() >= 1) {
            this.setState({
                ...this.state,
                pageChangeLink: `/member/details/${row.id}`,
            },
                () => {
                    this.showPageChangeAlert(); // Call any other functions that depend on the updated state
                }
            );
        }
        else {
            this.props.history.push(`/member/details/${row.id}`);
        }
    };
    proceedWithPageChange = () => {
        this.props.history.push(this.state.pageChangeLink);
    }
    updateSelectedMembers = (dataToAdd, isSelect) => {
        if (!isSelect) {
            if (Array.isArray(dataToAdd)) {
                for (const member of dataToAdd) {
                    this.removeSelectedMember(member);
                }
            }
            else {
                this.removeSelectedMember(dataToAdd);
            }
        } else {
            if (Array.isArray(dataToAdd)) {
                for (const member of dataToAdd) {
                    this.addSelectedMember(member);
                }
            }
            else {
                this.addSelectedMember(dataToAdd);
            }
        }
    }

    addSelectedMember = (row) => {
        this.setState(prevState => ({
            selectedMembers: [...prevState.selectedMembers, row]
        }));
    }
    removeSelectedMember = (row) => {
        this.setState(prevState => ({
            selectedMembers: prevState.selectedMembers.filter(member => member.utuAccount !== row.utuAccount)
        }));
    }
    getSelectedMembersCount = () => {
        return this.state.selectedMembers.length;
    }

    handleFunctionChange = (selectedFunction) => {
        if (selectedFunction !== null) {
            this.setState({
                ...this.state,
                allowFunctionsExecution: true,
                selectedFunction: selectedFunction,
                functionsExecutionSuccess: null,
                showFunctionsModal: true,
                functionsExecutionMessage: ''
            });
        } else {
            this.setState({ ...this.state, selectedFunction: null });
        }

    };

    handleAcceptanceOptionChange = (event) => {
        this.setState({
            ...this.state,
            selectedAcceptanceStatus: (event.target.value === 'accepted') ? true : false,
            allowFunctionsExecution: true,
            functionsExecutionSuccess: null,
            functionsExecutionMessage: ''
        });
    }

    closeImportModal = () => {
        this.setState({ ...this.state, showImportModal: false });
    };
    onFunctionsModalOpen = () => {
        this.setState({
            ...this.state,
            selectedFunction: null,
            functionsExecutionSuccess: null,
            showFunctionsModal: true,
            functionsExecutionMessage: ''
        });
    };

    closeFunctionsModal = () => {
        this.setState({
            ...this.state,
            showFunctionsModal: false
        });
    }

    showPageChangeAlert = () => {
        this.setState({
            ...this.state,
            showPageChangeAlert: true
        });
    }

    closePageChangeAlert = () => {
        this.setState({
            ...this.state,
            showPageChangeAlert: false
        });
    }

    handleExport = (props) => {
        props.onExport();
    };

    perfomSelectedFunction = async () => {
        const selectedFunction = this.state.selectedFunction;
        this.setState({ allowFunctionsExecution: false });
        const responses = [];
        try {
            for (let i = 0; i < this.state.selectedMembers.length; i++) {
                const memberData = {
                    firstName: this.state.selectedMembers[i].firstName,
                    lastName: this.state.selectedMembers[i].lastName,
                    utuAccount: this.state.selectedMembers[i].utuAccount,
                    email: this.state.selectedMembers[i].email,
                    homeTown: this.state.selectedMembers[i].homeTown,
                    tyyMember: this.state.selectedMembers[i].tyyMember,
                    membershipSince: this.state.selectedMembers[i].membershipSince,
                    membershipUntil: this.state.membershipUntil,
                    password: this.state.selectedMembers[i].password,
                    passwordAgain: this.state.selectedMembers[i].passwordAgain,
                    accepted: this.state.selectedMembers[i].accepted,
                    memberID: this.state.selectedMembers[i].id,
                };

                if (selectedFunction.value === 'changeAcceptedStatus') {
                    const selectedAcceptanceStatus = this.state.selectedAcceptanceStatus;
                    responses[i] = await api.put('/admin/mass-change-acceptance', { 'member': memberData, 'accepted': selectedAcceptanceStatus }, {
                        headers: {
                            Authorization: getCookie('jasenrekisteri-token'),
                            'Content-Type': 'application/json',
                        },
                    });
                }
                else if (selectedFunction.value === 'giveMasterFresherRole') {
                    const privilegesStartDate = new Date();
                    const privilegesEndDate = new Date().getUTCMonth() > 6 ? new Date(privilegesStartDate.getFullYear() + 1, 6, 31) : new Date(privilegesStartDate.getFullYear(), 6, 31);
                    responses[i] = await api.post(
                        '/admin/mass-update-privileges',
                        { privileges: [{ 'endDate': privilegesEndDate, 'startDate': privilegesStartDate, memberId: this.state.selectedMembers[i].id, role: 'masterfresher' }] },
                        {
                            headers: {
                                Authorization: getCookie('jasenrekisteri-token'),
                                'Content-Type': 'application/json',
                            },
                            params: {
                                memberID: this.state.selectedMembers[i].id,
                                all: true,
                            },
                        }
                    );

                }
                else if (selectedFunction.value === 'giveBachelorFresherRole') {
                    const privilegesStartDate = new Date();
                    const privilegesEndDate = new Date().getUTCMonth() > 6 ? new Date(privilegesStartDate.getFullYear() + 1, 6, 31) : new Date(privilegesStartDate.getFullYear(), 6, 31);
                    responses[i] = await api.post(
                        '/admin/mass-update-privileges',
                        { privileges: [{ 'endDate': privilegesEndDate, 'startDate': privilegesStartDate, memberId: this.state.selectedMembers[i].id, role: 'bachelorfresher' }] },
                        {
                            headers: {
                                Authorization: getCookie('jasenrekisteri-token'),
                                'Content-Type': 'application/json',
                            },
                            params: {
                                memberID: this.state.selectedMembers[i].id,
                                all: true,
                            },
                        }
                    );

                } else if (selectedFunction.value === 'sendPasswordResetLink') {
                    responses[i] = await api.post(
                        '/forgot/authenticated',
                        { email:  memberData.email },
                        {
                            headers: {
                                Authorization: getCookie('jasenrekisteri-token'),
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                }

                if (responses.filter(response => response.data.success === true).length === this.state.selectedMembers.length) {
                    this.setState({
                        ...this.state,
                        allowFunctionsExecution: true,
                        functionsExecutionSuccess: responses[0].data.success,
                        functionsExecutionMessage: responses[0].data.message,
                    });
                }
            }
        } catch (e) {
            const failedRequest = responses.find(response => response.data.success === false)
            this.setState({
                ...this.state,
                allowFunctionsExecution: true,
                functionsExecutionSuccess: failedRequest.success,
                functionsExecutionMessage: failedRequest.message,
            });
        }
    }

    getSelectedFunctionsValue = () => {
        return this.state.selectedFunction ? this.state.selectedFunction.value : null;
    }


    showImportModal = (message) => {
        this.setState({
            ...this.state,
            showImportModal: true,
            importModalMessage: message,
        });
    };

    showMembersPurgeModal = () => {
        this.setState({
            ...this.state,
            showMembersPurgeModal: true,
            membersPurgeInProgress: false,
            membersPurgeDate: new Date(),
            membersPurgeResultMessage: '',
            membersPurgeCurrentCount: null,
            membersPurgeMaxCount: null,
            membersPurgeSuccess: false,
        });
    };

    handlePurgeDateChange = (date) => {
        this.setState({
            membersPurgeDate: date,
        });
    };

    closeMembersPurgeModal = () => {
        this.setState({
            ...this.state,
            showMembersPurgeModal: false,
        });
    };




    render() {

        const FunctionsExecutionButton = (props) => {
            const handleClick = () => {
                props.onExport();
            }
            return (
                <div>
                    <Button
                        style={{ float: "right", display: this.getSelectedFunctionsValue() === 'exportToCSV' ? 'block' : 'none' }}
                        variant="success"
                        disabled={!this.state.allowFunctionsExecution}
                        className={`${this.state.allowFunctionsExecution ? '' : 'notClickable'}`}
                        onClick={handleClick}
                    >
                        Suorita
                    </Button>
                    <Button
                        style={{ float: "right", display: this.getSelectedFunctionsValue() !== 'exportToCSV' ? 'block' : 'none' }}
                        variant="success"
                        disabled={!this.state.allowFunctionsExecution}
                        className={`${this.state.allowFunctionsExecution ? '' : 'notClickable'}`}
                        onClick={this.perfomSelectedFunction}>
                        Suorita
                    </Button>
                </div>
            )
        }
        const functionsSelectOptions = [
            { value: 'changeAcceptedStatus', label: 'Vaihda hyväksynnän tilaa' },
            { value: 'giveMasterFresherRole', label: 'Anna maisterifuksirooli' },
            { value: 'giveBachelorFresherRole', label: 'Anna kandifuksirooli' },
            { value: 'sendPasswordResetLink', label: 'Lähetä salasanan nollauslinkki' },
            { value: 'exportToCSV', label: 'Vie CSV:nä' },
        ]
        const functionsMemberListSort = [
            {
                dataField: 'firstName',
                order: 'desc',
            },
        ];
        const functionsMemberListColumns = [
            {
                dataField: 'firstName',
                text: 'Etunimi',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                style: { textTransform: 'capitalize' },
                headerStyle: {
                    width: '150px',
                },
            },
            {
                dataField: 'lastName',
                text: 'Sukunimi',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerStyle: {
                    width: '150px',
                },
            },
            {
                dataField: 'utuAccount',
                text: 'UTU',
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerStyle: {
                    width: '100px',
                },
            },
            {
                dataField: 'email',
                text: 'Email',
                style: { display: 'none' },
                headerStyle: {
                    display: 'none',
                },
            },
            {
                dataField: 'homeTown',
                text: 'Kotikunta',
                style: { display: 'none' },
                headerStyle: {
                    display: 'none',
                },
            },
            {
                dataField: 'tyyMember',
                text: 'TYYn jäsen',
                style: { display: 'none' },
                headerStyle: {
                    display: 'none',
                },
            },
            {
                dataField: 'accepted',
                text: 'Hyväksytty',
                style: { display: 'none' },
                headerStyle: {
                    display: 'none',
                },
            },
            {
                dataField: 'membershipSince',
                text: 'Alkanut',
                style: { display: 'none' },
                headerStyle: {
                    display: 'none',
                },
            },
            {
                dataField: 'membershipUntil',
                text: 'Päättyy',
                style: { display: 'none' },
                headerStyle: {
                    display: 'none',
                },
            },
        ];
        const multiselectDropdownStyles = {
            // select box styles
            control: (provided, state) => ({
                ...provided,
                borderColor: '#fecd32',
                boxShadow: '0 0 0 0',
                '&:hover': {
                    borderColor: '#fecd32',
                    boxShadow: '0 0 0 2px #fecd32',
                },
                '&:active': {
                    borderColor: '#fecd32',
                    boxShadow: '0 0 0 2px #fecd32',
                },
            }),
            // option styles
            option: (provided, state) => ({
                ...provided,
                backgroundColor: 'white',
                color: 'black',
                '&:hover': {
                    backgroundColor: '#fecd32',
                    color: 'black'
                },
            }),
        };
        const {
            isLoading,
            members,
            success,
            message,
            memberNotFound,
            handlingCsv,
            showImportModal,
            showMembersPurgeModal,
            membersPurgeDate,
            membersPurgeInProgress,
            membersPurgeCurrentCount,
            membersPurgeMaxCount,
            membersPurgeResultMessage,
            membersPurgeSuccess,
            showFunctionsModal,
            selectedFunction,
            selectedAcceptanceStatus,
            functionsExecutionMessage,
            functionsExecutionSuccess,
            showPageChangeAlert,
            importModalMessage,
            importModalCurrentCount,
            importModalMaxCount,
            importModalTimeEstimationSeconds,
        } = this.state;

        if (isLoading) {
            return <PreloaderComponent />;
        }

        if (memberNotFound) {
            return (
                <div>
                    <MemberNotFoundComponent />
                </div>
            );
        }

        return (

            <div>
                <ToolkitProvider
                    bootstrap4
                    keyField="utuAccount"
                    data={this.state.selectedMembers}
                    columns={functionsMemberListColumns}
                    defaultSorted={functionsMemberListSort}
                    exportCSV={{
                        fileName: 'members.csv',
                        separator: ',',
                        ignoreHeader: true,
                    }}
                >
                    {(props) => (

                        <div>
                            {/* multiselect modal start */}
                            <Modal
                                show={showFunctionsModal}
                                onHide={this.closeFunctionsModal}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Massatoimintosuoritus
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="container-fluid">
                                        <div className="functionsMemberList">
                                            <BootstrapTable
                                                {...props.baseProps}
                                                striped
                                                hover
                                                classes="functionsMemberListTable"
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="membercount float-right">
                                                    <p>
                                                        Jäseniä yhteensä{' '}
                                                        {Object.keys(props.baseProps.data).length}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h4>Valitse suoritettava toiminto:</h4>
                                        <Select
                                            styles={multiselectDropdownStyles}
                                            onChange={this.handleFunctionChange} placeholder="Valitse toiminto" value={selectedFunction ? selectedFunction : null} options={functionsSelectOptions}></Select>
                                        <div className="functionDescText" style={{ display: this.getSelectedFunctionsValue() === 'changeAcceptedStatus' ? 'block' : 'none' }}>
                                            <p>Hyväksytty:</p>
                                            <div >
                                                <input type="radio" onChange={this.handleAcceptanceOptionChange} id="acceptedStatusTrue"
                                                    checked={selectedAcceptanceStatus === true} name="changeMemberAcceptedStatus" value="accepted"></input>
                                                <label htmlFor="acceptedStatusTrue">Kyllä <FontAwesomeIcon icon="check" color="green" /></label>
                                                <br />
                                                <input type="radio" onChange={this.handleAcceptanceOptionChange} id="acceptedStatusFalse"
                                                    checked={selectedAcceptanceStatus === false} name="changeMemberAcceptedStatus" value="notAccepted"></input>
                                                <label htmlFor="acceptedStatusFalse">Ei <FontAwesomeIcon icon="times" color="red" /></label>
                                            </div>
                                        </div>
                                        <div className="functionDescText" style={{ display: this.getSelectedFunctionsValue() === 'giveMasterFresherRole' ? 'block' : 'none' }}>
                                            <p>Maisterifuksirooli annetaan täksi lukuvuodeksi.</p>
                                        </div>
                                        <div className="functionDescText" style={{ display: this.getSelectedFunctionsValue() === 'giveBachelorFresherRole' ? 'block' : 'none' }}>
                                            <p>Kandifuksirooli annetaan täksi lukuvuodeksi.</p>
                                        </div>
                                        <div className="functionDescText" style={{ display: this.getSelectedFunctionsValue() === 'sendPasswordResetLink' ? 'block' : 'none' }}>
                                            <p>Lähetä jäsenen sähköpostiin salasanan nollauslinkki joka on voimassa 48h.</p>
                                        </div>
                                        <div className="functionDescText" style={{ display: this.getSelectedFunctionsValue() === 'exportToCSV' ? 'block' : 'none' }}>
                                            <p>Valitut jäsenet viedään CSV:nä.</p>
                                        </div>
                                        <div style={{ display: functionsExecutionMessage ? "block" : "none" }}>
                                            <Alert variant={!functionsExecutionSuccess ? 'danger' : 'success'}>
                                                {functionsExecutionMessage}
                                            </Alert>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ display: 'block' }}>
                                    <Button style={{ float: "left" }} variant="error" onClick={this.closeFunctionsModal}>
                                        Peruuta
                                    </Button>
                                    <FunctionsExecutionButton
                                        {...props.csvProps}
                                    ></FunctionsExecutionButton>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={showPageChangeAlert}
                                onHide={this.closePageChangeAlert}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Varoitus
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <p>Haluatko varmasti poistua nykyiseltä sivulta? Tällöin jäsenien valinta poistuu.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ display: 'block' }}>
                                    <Button style={{ float: "left" }} variant="error" onClick={this.closePageChangeAlert}>
                                        Peruuta
                                    </Button>
                                    <Button style={{ float: "right" }} variant="success" onClick={this.proceedWithPageChange}>
                                        Kyllä
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    )}
                </ToolkitProvider>
                {/* expired members purge modal start */}
                <Modal
                    backdrop="static"
                    show={showMembersPurgeModal}
                    onHide={this.closeMembersPurgeModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Jäsenien puhdistus
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p>Valitse päivä, johon mennessä erääntyneiden jäsenyyksien osalta jäsenien poisto suoritetaan.</p>
                        <div className="spacing">
                            <span className="d-block">Päivämäärä</span>
                            <DatePicker
                                selected={membersPurgeDate ? membersPurgeDate : new Date()}
                                onChange={this.handlePurgeDateChange}
                                dateFormat="dd.MM.yyyy"
                                className="form-control"
                                locale="fi"
                            />
                        </div>
                        <div className="spacing" style={{ display: membersPurgeInProgress ? "block" : "none" }}>
                            <div><b>Poistetaan jäseniä. Jos niitä on paljon, tässä voi mennä useita minuutteja. Älä sulje tätä ikkunaa kesken poiston.</b></div>
                            <div style={{ position: "relative", height: "50px" }}>
                                {membersPurgeCurrentCount && membersPurgeMaxCount && (
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                                width: `${(membersPurgeCurrentCount /
                                                        membersPurgeMaxCount) *
                                                    100
                                                    }%`,
                                            }}
                                            aria-valuenow={
                                                (membersPurgeCurrentCount /
                                                    membersPurgeMaxCount) *
                                                100
                                            }
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {(
                                                (membersPurgeCurrentCount /
                                                    membersPurgeMaxCount) *
                                                100
                                            ).toFixed(2)}
                                            %
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="spacing" style={{ display: membersPurgeResultMessage !== "" ? "block" : "none" }}>
                            <Alert variant={membersPurgeSuccess ? 'success' : 'danger'}>
                                {membersPurgeResultMessage}
                            </Alert>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "space-between" }}>
                        <Button style={{ float: "left" }} variant="error" onClick={this.closeMembersPurgeModal}>
                            Peruuta
                        </Button>
                        <Button
                            className={`${membersPurgeInProgress ? "notClickable" : ""}`}
                            style={{ float: "right" }} variant="danger" onClick={membersPurgeInProgress ? null : this.handleMembersPurge}>
                            Poista
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* members import modal start */}
                <Modal
                    show={showImportModal}
                    onHide={this.closeImportModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ilmoitus
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{importModalMessage}</p>
                        {importModalCurrentCount && importModalMaxCount && (
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        width: `${(importModalCurrentCount /
                                                importModalMaxCount) *
                                            100
                                            }%`,
                                    }}
                                    aria-valuenow={
                                        (importModalCurrentCount /
                                            importModalMaxCount) *
                                        100
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {(
                                        (importModalCurrentCount /
                                            importModalMaxCount) *
                                        100
                                    ).toFixed(2)}
                                    %
                                </div>
                            </div>
                        )}
                        {importModalTimeEstimationSeconds && (
                            <p>
                                Tuonti valmis arviolta{' '}
                                {importModalTimeEstimationSeconds} sekunnissa.
                            </p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-success"
                            onClick={this.closeImportModal}
                            type="reset"
                        >
                            Takaisin
                        </button>
                    </Modal.Footer>
                </Modal>
                <MemberListView
                    list={members}
                    success={success}
                    updateSelectedMembers={this.updateSelectedMembers}
                    message={message}
                    handleClick={this.handleClick}
                    onImport={this.onImport}
                    selectedMembersCount={this.getSelectedMembersCount}
                    onMembersPurgeModalOpen={this.showMembersPurgeModal}
                    onFunctionsModalOpen={this.onFunctionsModalOpen}
                    isLoading={this.isLoading}
                    handlingCsv={handlingCsv}
                />
            </div>
        );
    }

    handleMembersPurge = async () => {
        this.setState({ ...this.state, membersPurgeInProgress: true });
        try {
            const membersToPurge = await api.get(
                '/admin/members-to-purge?date=' + Moment(this.state.membersPurgeDate).format('YYYY-MM-DD'),
                {
                    headers: {
                        Authorization:
                            getCookie(
                                'jasenrekisteri-token'
                            ),
                        'Content-Type':
                            'application/json',
                    },
                }
            )
            const delay = 4000; // 4 seconds
            const membersPurgeResults = [];
            let delayCounter = 0;

            for (let i = 0; i < membersToPurge.data.members.length; i++) {
                try {
                    const result = await api.post('/admin/remove',
                        {
                            memberID: membersToPurge.data.members[i].id,
                            email: membersToPurge.data.members[i].email,
                        },
                        {
                            headers: {
                                Authorization:
                                    getCookie(
                                        'jasenrekisteri-token'
                                    ),
                                'Content-Type':
                                    'application/json',
                            },
                        }
                    )
                    membersPurgeResults.push({
                        "memberID": membersToPurge.data.members[i].id,
                        "email": membersToPurge.data.members[i].email,
                        "success": result.data.success
                    });
                    if (delayCounter >= 6) {
                        delayCounter = 0;
                        await new Promise((resolve) =>
                            setTimeout(resolve, delay)
                        );
                    }
                    else {
                        delayCounter++;
                    }
                    this.setState({
                        ...this.state,
                        membersPurgeInProgress: true,
                        showMembersPurgeModal: true,
                        membersPurgeCurrentCount:
                            i + 1,
                        membersPurgeMaxCount: membersToPurge.data.members.length,
                    });
                }
                catch (error) {
                    membersPurgeResults.push({ "memberID": membersToPurge.data.members[i].id, "email": membersToPurge.data.members[i].email, success: false });
                }
            }
            let resultMessage = '';
            if (membersPurgeResults.find(result => result.success === false)) {
                resultMessage = `Erääntyneiden jäsenien poisto epäonnistui. ${membersPurgeResults.filter(result => result.success === true).length} jäsentä poistettu. Loput näkyvät automaattisesti latautuvassa tiedostossa.`;
                this.setState({
                    ...this.state,
                    membersPurgeInProgress: false,
                    membersPurgeResultMessage: resultMessage,
                    membersPurgeSuccess: false,
                    membersPurgeCurrentCount: null,
                    membersPurgeMaxCount: null,
                });

                const failedRemovals = membersPurgeResults.filter(result => result.success === false);
                const csvString = await csv.writeToString(failedRemovals, {
                    headers: false,
                    delimiter: ',',
                });
                const blob = new Blob([csvString], {
                    type: 'text/csv;charset=utf-8;',
                });
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = 'failed-removals.csv';
                downloadLink.click();

            }
            else {
                resultMessage = `Erääntyneiden jäsenien poisto onnistui! ${membersToPurge.data.members.length} jäsentä poistettu.`;
                this.setState({
                    ...this.state,
                    membersPurgeInProgress: false,
                    membersPurgeResultMessage: resultMessage,
                    membersPurgeSuccess: true,
                    membersPurgeCurrentCount: null,
                    membersPurgeMaxCount: null,
                });
            }
        }
        catch (error) {
            let resultMessage = `Erääntyneiden jäsenien poisto epäonnistui tuntemattoman virheen takia.`;
            this.setState({ ...this.state, membersPurgeInProgress: false, membersPurgeResultMessage: resultMessage });
        }
    }

    onImport = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.csv';
        input.onchange = async (pickerEvent) => {
            const file = pickerEvent.target.files[0];
            if (!file) {
                return;
            }
            this.setState({
                ...this.state,
                handlingCsv: true,
            });
            const reader = new FileReader();

            // create new promise to await
            const failedRows = await new Promise((resolve) => {
                const failed = [];
                const rows = [];
                reader.onload = async (readerEvent) => {
                    const stream = readerEvent.target.result;

                    csv.parseString(stream, {
                        headers: false,
                        delimiter: ',',
                    })
                        .on('data', async (data) => {
                            rows.push(data);
                        })
                        .on('end', async () => {
                            const delay = 4000; // 4 seconds
                            const batchSize = 6;
                            const batches = [];

                            // Split rows into batches of batchSize
                            for (let i = 0; i < rows.length; i += batchSize) {
                                batches.push(rows.slice(i, i + batchSize));
                            }

                            // Send each batch with a delay of delay milliseconds
                            for (let i = 0; i < batches.length; i++) {
                                this.setState({
                                    ...this.state,
                                    showImportModal: true,
                                    importModalMessage: 'Tuodaan jäseniä...',
                                    importModalCurrentCount:
                                        (i + 1) * batchSize,
                                    importModalMaxCount: rows.length,
                                    importModalTimeEstimationSeconds: Math.ceil(
                                        (batches.length - (i + 1)) *
                                        (delay / 1000)
                                    ),
                                });
                                const batch = batches[i];
                                await Promise.all(
                                    batch.map(async (data) => {
                                        try {
                                            const newData = {
                                                firstName: data[0],
                                                lastName: data[1],
                                                utuAccount:
                                                    data[2]?.toLowerCase(),
                                                email: data[3],
                                                homeTown: data[4],
                                                tyyMember:
                                                    `${data[5]}` !== 'null'
                                                        ? data[5].toLowerCase() ===
                                                        'true'
                                                        : false,
                                                accepted:
                                                    `${data[6]}` !== 'null'
                                                        ? data[6].toLowerCase() ===
                                                        'true'
                                                        : false,
                                                membershipSince:
                                                    `${data[7]}` !== 'null'
                                                        ? new Date(data[7])
                                                        : null,
                                                membershipUntil:
                                                    `${data[8]}` !== 'null'
                                                        ? new Date(data[8])
                                                        : null,
                                            };

                                            await api.post(
                                                '/admin/new',
                                                newData,
                                                {
                                                    headers: {
                                                        Authorization:
                                                            getCookie(
                                                                'jasenrekisteri-token'
                                                            ),
                                                        'Content-Type':
                                                            'application/json',
                                                    },
                                                }
                                            );
                                        } catch (error) {
                                            failed.push(data);
                                        }
                                    })
                                );
                                if (i < batches.length - 1) {
                                    await new Promise((resolve) =>
                                        setTimeout(resolve, delay)
                                    );
                                }
                            }

                            resolve(failed);
                        });
                };

                reader.readAsText(file);
            });

            if (failedRows.length > 0) {
                const csvString = await csv.writeToString(failedRows, {
                    headers: false,
                    delimiter: ',',
                });
                const blob = new Blob([csvString], {
                    type: 'text/csv;charset=utf-8;',
                });
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = 'failed-imports.csv';
                downloadLink.click();
            }
            const importModalSuccessMessage = `Jäsentietojen tuonti CSV:stä onnistui!`;
            const importModalFailedMessage = `\n\nTuntemattomasta syystä ${failedRows.length} rivin tuonti epäonnistui, ja näille ei ole luotu käyttäjää rekisteriin. Epäonnistuneet rivit on kirjattu tiedostoon failed-imports.csv, joka latautuu automaattisesti.`;

            this.setState({
                ...this.state,
                showImportModal: true,
                importModalMessage: failedRows.length > 0 ? importModalFailedMessage : importModalSuccessMessage,
                importModalCurrentCount: null,
                importModalMaxCount: null,
                importModalTimeEstimationSeconds: null,
            });

            this.setState({
                ...this.state,
                handlingCsv: false,
            });
        };

        input.click();
    };

    async componentDidMount() {
        try {
            let membersData = await api.get('/admin/list', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });

            const members = await Promise.all(
                membersData.data.map(async (member) => {
                    console.log(member)
                    // const capData = await api.get('/fresherpass/validate/cap', {
                    //     headers: {
                    //         Authorization: getCookie('jasenrekisteri-token'),
                    //         'Content-Type': 'application/json',
                    //     },
                    //     params: {
                    //         memberID: member.id,
                    //     },
                    // });

                    // const patchData = await api.get(
                    //     '/fresherpass/validate/patch',
                    //     {
                    //         headers: {
                    //             Authorization: getCookie(
                    //                 'jasenrekisteri-token'
                    //             ),
                    //             'Content-Type': 'application/json',
                    //         },

                    //         params: {
                    //             memberID: member.id,
                    //         },
                    //     }
                    // );

                    // const membershipData = await api.get('/member/valid', {
                    //     headers: {
                    //         Authorization: getCookie('jasenrekisteri-token'),
                    //         'Content-Type': 'application/json',
                    //     },
                    //     params: {
                    //         memberID: member.id,
                    //     },
                    // });

                    return {
                        ...member,
                        // entitledToCap: capData.data,
                        // entitledToPatch: patchData.data,
                        // membershipValid: membershipData.data.isValid,
                    };
                })
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    memberNotFound: members.memberNotFound,
                    members,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiselle epäonnistui.',
                    isLoading: false,
                    memberNotFound: true,
                },
            });
        }
    }
}

export default withRouter(MemberListComponent);
