export const getPrivilegeString = (privileges) => {
    if (!privileges) return 'Jäsen';
    const privilegeString = privileges
        .map((p) => privilegeSwitchCase(p))
        .join(', ');
    return privilegeString;
};

const privilegeSwitchCase = (privilege) => {
    switch (privilege) {
        case 'chairman':
            return 'Hallitus PJ';
        case 'vicechairman':
            return 'Hallitus VPJ';
        case 'admin':
            return 'Admin';
        case 'boardmember':
            return 'Hallitus';
        case 'functionary':
            return 'Toimihenkilö';
        case 'tutor':
            return 'Tutor';
        case 'bachelorfresher':
            return 'Fuksi (kandi)';
        case 'masterfresher':
            return 'Fuksi (maisteri)';
        case 'kiltisban':
            return 'Kiltis Ban';
        default:
            return '-';
    }
};
