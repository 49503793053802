import React from 'react';
import './publicAnnouncementDetails.css';
import { Alert, Form } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import Moment from 'moment';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';

const PublicAnnouncementDetailsView = ({
    announcement,
    message,
    success,
    isLoading,
    setLanguage,
    language,
    announcer,
}) => {
    if (isLoading) {
        return <PreloaderComponent />;
    }

    const content = announcement['content' + language];
    const title = announcement['title' + language];
    const firstName = announcer.firstName;
    const lastName = announcer.lastName;
    const photoUrl =
        announcer.photoUrl ??
        'https://eu.ui-avatars.com/api/?name=' +
            firstName +
            '+' +
            lastName +
            '&size=50&bold=true&length=2&format=png';

    return (
        <div className="announcement-form">
            <div id="editor"></div>
            {message && !success ? (
                <Alert variant={!success ? 'danger' : 'success'}>
                    {message}
                </Alert>
            ) : null}
            <div className="language-row">
                <Form.Control
                    as="select"
                    className="language-select"
                    value={language}
                    onChange={(e) => {
                        e.preventDefault();
                        setLanguage(e.target.value);
                    }}
                >
                    <option value="FI">Suomi</option>
                    <option value="EN">English</option>
                </Form.Control>
            </div>
            {!isLoading && title ? <h2>{title}</h2> : null}

            <div className="details-row">
                <p>{Moment(announcement.publishedAt).format('D.M.YYYY')}</p>
                {announcer && (
                    <div className="announcer">
                        <p>
                            {firstName}
                            <br></br>
                            {lastName}
                        </p>
                        <img src={photoUrl} alt="img"></img>
                    </div>
                )}
            </div>
            {!isLoading && content ? (
                <ReactQuill
                    theme="snow"
                    value={content}
                    readOnly={true}
                    modules={{ toolbar: false }}
                />
            ) : (
                <p>Tiedotteella ei ole sisältöä</p>
            )}
            <h5>Liitteet</h5>
            {announcement.attachments &&
                announcement.attachments.length <= 0 && <p>Ei liitteitä</p>}
            {announcement.attachments &&
                announcement.attachments.map((a) => (
                    <a href={a} key={a}>
                        {a.split('--fn--').pop()}
                    </a>
                ))}
        </div>
    );
};

export default PublicAnnouncementDetailsView;
