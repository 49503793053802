import React from 'react';

import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';

import { getPrivilegeString } from '../../../utils/privileges';

Moment.locale('fi');

const currentDate = new Date();

function membershipUntilFormatter(membershipUntil, currentDate) {
    if (membershipUntil) {
        if (new Date(membershipUntil) <= currentDate) {
            return (
                <div>
                    {Moment(membershipUntil).format('D.M.YYYY')}{' '}
                    <FontAwesomeIcon icon="exclamation-triangle" color="red" />
                </div>
            );
        } else {
            return <div>{Moment(membershipUntil).format('D.M.YYYY')}</div>;
        }
    } else {
        return <div>Jäsenmaksua ei ole maksettu</div>;
    }
}

const MemberDetailsView = ({
    firstName,
    lastName,
    utuAccount,
    email,
    homeTown,
    privileges,
    membershipSince,
    membershipUntil,
    accepted,
    tyyMember,
}) => (
    <div className="container">
        <Table striped hover>
            <tbody>
                <tr>
                    <th>Etunimi</th>
                    <td style={{ textTransform: 'capitalize' }}>{firstName}</td>
                </tr>
                <tr>
                    <th>Sukunimi</th>
                    <td>{lastName}</td>
                </tr>
                <tr>
                    <th>UTU-tunnus</th>
                    <td>{utuAccount}</td>
                </tr>
                <tr>
                    <th>Sähköpostiosoite</th>
                    <td>{email}</td>
                </tr>
                <tr>
                    <th>Kotikunta</th>
                    <td>{homeTown}</td>
                </tr>
                <tr>
                    <th>TYYn jäsen</th>
                    <td>
                        {tyyMember ? (
                            <p>
                                Kyllä{' '}
                                <FontAwesomeIcon icon="check" color="green" />
                            </p>
                        ) : (
                            <p>
                                Ei <FontAwesomeIcon icon="times" color="red" />
                            </p>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Roolit</th>
                    <td>{getPrivilegeString(privileges)}</td>
                </tr>
                <tr>
                    <th>Jäsenyys hyväksytty</th>
                    <td>
                        {accepted ? (
                            <p>
                                Kyllä{' '}
                                <FontAwesomeIcon icon="check" color="green" />
                            </p>
                        ) : (
                            <p>
                                Ei <FontAwesomeIcon icon="times" color="red" />
                            </p>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Jäsenyys alkanut</th>
                    <td>
                        {membershipSince
                            ? Moment(membershipSince).format('D.M.YYYY')
                            : 'Jäsenmaksua ei ole maksettu'}
                    </td>
                </tr>
                <tr>
                    <th>Jäsenyys päättyy</th>
                    <td>
                        {membershipUntilFormatter(membershipUntil, currentDate)}
                    </td>
                </tr>
            </tbody>
        </Table>
        <div>
            {membershipUntil && new Date(membershipUntil) <= currentDate ? (
                <p>
                    <FontAwesomeIcon icon="exclamation-triangle" color="red" />{' '}
                    Jäsenyytesi on päättynyt. Maksa jäsenmaksusi.
                </p>
            ) : null}
        </div>
        <Link className="btn btn-success success" to={`/member/update/`}>
            Päivitä tietoja
        </Link>
    </div>
);

export default MemberDetailsView;
