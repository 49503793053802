import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import {
    canAnnounce,
    canManageRegistry,
    canSeeAccounting,
} from '../../../utils/cookies';
import config from '../../../config';

import './headerComponent.css';

import digitlogo from '../../assets/digit.webp';
import '../../assets/scaling-logo.css';

class HeaderComponent extends Component {
    render() {
        return (
            <div className="header-container">
                <Navbar variant="dark" expand="md">
                    <Navbar.Brand href="/member">
                        <img
                            alt="navbar-brand"
                            width="32"
                            height="32"
                            className="d-inline-block align-top rotating-logo"
                            src={digitlogo}
                        />{' '}
                        <p>Jäsenrekisteri</p>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            {(canAnnounce() ||
                                canManageRegistry() ||
                                canSeeAccounting()) && (
                                <NavDropdown
                                    title="Hallinta"
                                    id="basic-nav-dropdown"
                                >
                                    {canManageRegistry() && (
                                        <NavDropdown.Item href="/admin">
                                            Rekisteri
                                        </NavDropdown.Item>
                                    )}
                                    {canSeeAccounting() && (
                                        <NavDropdown.Item href="/admin/purchases">
                                            Kirjanpito
                                        </NavDropdown.Item>
                                    )}
                                    {canAnnounce() && (
                                        <NavDropdown.Item href="/admin/announcements">
                                            Tiedotus
                                        </NavDropdown.Item>
                                    )}
                                </NavDropdown>
                            )}

                            <Nav.Link href="/announcements">
                                Tiedotteet
                            </Nav.Link>
                            <Nav.Link href="/member">Omat tiedot</Nav.Link>
                            {config.paymentOptions ? (
                                <Nav.Link href="/member/pay">Kauppa</Nav.Link>
                            ) : null}
                            <Nav.Link href="/logout">Kirjaudu ulos</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default HeaderComponent;
