import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import MainComponent from '../../commons/main/mainComponent';

import { checkCookie } from '../../../utils/cookies';
import config from '../../../config';

import './loginView.css';

const LoginView = ({ success, message, handleLogin, handleInputChange }) => (
    <div className="loginview_container">
        <MainComponent>
            <h3 className="text-center">Jäsenrekisteri</h3>
            {checkCookie() !== null ? <Redirect to="/admin" /> : null}
            {message ? (
                <Alert variant={!success ? 'danger' : 'success'}>
                    {message}
                </Alert>
            ) : null}
            <Form onSubmit={handleLogin} className="login-form">
                <Form.Group>
                    <Form.Label>Sähköposti</Form.Label>
                    <Form.Control
                        type="text"
                        name="email"
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Salasana</Form.Label>
                    <Form.Control
                        type="password"
                        name="password"
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Button className="button-main" type="submit" variant="success">
                    <FontAwesomeIcon icon="sign-in-alt" /> Kirjaudu sisään
                </Button>
            </Form>
            <hr />
            <div className="btm-links">
                <div>
                    {config.paymentOptions ? (
                        <p>
                            <Link to="join">Liity jäseneksi</Link>
                        </p>
                    ) : null}
                    <p>
                        <Link to="forgot">Salasana unohtunut?</Link>
                    </p>
                    <p>
                        <a href="https://www.digit.fi/viralliset/tietosuojaseloste/" rel="noopener noreferrer" target="_blank">
                            Jäsenrekisteriseloste
                        </a>
                    </p>
                </div>
                <div>
                    <p>
                        <a href="https://www.digit.fi/" rel="noopener noreferrer" target="_blank">
                            Digitin verkkosivut
                        </a>
                    </p>
                    <p>
                        <a href="mailto:digit@utu.fi" rel="noopener noreferrer" target="_blank">
                            Hallitus: digit [at] utu.fi
                        </a>
                    </p>
                </div>
            </div>
        </MainComponent>
    </div>
);

export default LoginView;
