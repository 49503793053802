import React from 'react';
import './announcementDetails.css';
import { Alert, Form } from 'react-bootstrap';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import {
    announcementTypeString,
    platformString,
} from '../../../utils/announcements';
import DatePicker from 'react-datepicker';

const AnnouncementDetailsView = ({
    announcement,
    handleInputChange,
    changeContentFI,
    changeContentEN,
    quillModules,
    save,
    message,
    success,
    publish,
    isLoading,
    deleteAnnouncement,
    archive,
    duplicate,
    makeTemplate,
    publishType,
    setPublishType,
    changePublishDate,
    handlePlatformChange,
    platforms,
    types,
    uploadAttachment,
    removeAttachment,
}) => {
    return (
        <div className="announcement-form">
            <div className="buttons-row">
                <p> </p>
                <div className="destructive-buttons">
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => archive()}
                        disabled={announcement.status === 'archived'}
                    >
                        Arkistoi
                    </button>
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => makeTemplate()}
                        disabled={announcement.status === 'template'}
                    >
                        Muuta tiedotepohjaksi
                    </button>
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => duplicate()}
                    >
                        Monista
                    </button>
                </div>
            </div>
            <ValidationForm
                onSubmit={(e) => {
                    e.preventDefault();
                    save();
                }}
            >
                {' '}
                <Form.Group>
                    <Form.Label>
                        <b>Ilmoituksen tyyppi</b>
                    </Form.Label>
                    <Form.Control
                        as="select"
                        name="type"
                        value={announcement.type}
                        required
                        errorMessage={{
                            required: 'Ilmoituksen tyyppi on pakollinen',
                        }}
                        onChange={handleInputChange}
                    >
                        {types.map((type) => (
                            <option value={type} key={type}>
                                {announcementTypeString(type)}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <b>Otsikko</b> FI
                    </Form.Label>
                    <TextInput
                        name="titleFI"
                        id="titleFI"
                        defaultValue={announcement.titleFI}
                        errorMessage={{
                            required: 'Otsikko on pakollinen',
                            pattern: 'Tarkista otsikko.',
                        }}
                        required
                        pattern="[a-zA-Z\u00c0-\u017e- ]$"
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <b>Otsikko</b> EN
                    </Form.Label>
                    <TextInput
                        name="titleEN"
                        id="titleEN"
                        defaultValue={announcement.titleEN}
                        errorMessage={{
                            required: 'Otsikko on pakollinen',
                            pattern: 'Tarkista otsikko.',
                        }}
                        required
                        pattern="[a-zA-Z\u00c0-\u017e- ]$"
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <b>Sisältö</b> FI
                    </Form.Label>
                    <ReactQuill
                        theme="snow"
                        value={announcement.contentFI}
                        onChange={changeContentFI}
                        modules={quillModules}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <b>Sisältö</b> EN
                    </Form.Label>
                    <ReactQuill
                        theme="snow"
                        value={announcement.contentEN}
                        onChange={changeContentEN}
                        modules={quillModules}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <b>Liitteet</b>
                    </Form.Label>
                    <div className="attachments-col">
                        <div className="attachments-row">
                            {announcement.attachments.map((attachment) => (
                                <div
                                    className="single-attachment"
                                    key={attachment}
                                >
                                    <p>{attachment.split('--fn--').pop()}</p>
                                    <p
                                        className="remove"
                                        onClick={() =>
                                            removeAttachment(attachment)
                                        }
                                    >
                                        x
                                    </p>
                                </div>
                            ))}
                        </div>
                        <label
                            for="file-picker"
                            className="btn btn-success file-picker-button"
                        >
                            Valitse tiedosto
                        </label>
                        <input
                            type="file"
                            id="file-picker"
                            onChange={uploadAttachment}
                        ></input>
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <b>Julkaisualustat</b>
                    </Form.Label>
                    {platforms.map((platform) => (
                        <Form.Check
                            type="checkbox"
                            key={platform}
                            checked={announcement.platforms.includes(platform)}
                            onChange={handlePlatformChange}
                            name={platform}
                            label={platformString(platform)}
                        />
                    ))}
                </Form.Group>
                {announcement.status === 'published' && (
                    <p>
                        Tämä tiedote on jo julkaistu, joten voit ainoastaan
                        muokata nettisivuilla ja mobiilisovelluksessa näkyvää
                        versiota.
                    </p>
                )}
                {announcement.status === 'template' && (
                    <p>
                        Tämä tiedote on merkitty tiedotepohjaksi, joten sitä ei
                        voi julkaista. Voit monistaa tämän tiedotteen ja
                        julkaista uuden version.
                    </p>
                )}
                {announcement.status === 'archived' && (
                    <p>
                        Tämä tiedote on arkistoitu, joten sitä ei voi julkaista.
                    </p>
                )}
                {announcement.status === 'scheduled' && (
                    <p>
                        Tämä tiedote on ajastettu julkaistavaksi myöhemmin.
                        Ennen automaattisen julkaisun tapahtumista voit vielä
                        muuttaa ajankohtaa tai poistaa automaattisen julkaisun
                        vaihtamalla julkaisuajankohdaksi "heti". Tällöin
                        julkaisu tapahtuu vain painamalla "julkaise" -nappia.
                    </p>
                )}
                {(announcement.status === 'draft' ||
                    announcement.status === 'scheduled') && (
                    <>
                        {' '}
                        <Form.Group>
                            <Form.Label>
                                <b>Julkaisuajankohta</b>
                            </Form.Label>
                            <Form.Control
                                as="select"
                                name="publishType"
                                value={publishType}
                                onChange={(e) => setPublishType(e.target.value)}
                            >
                                <option value="now">Heti</option>
                                <option value="scheduled">Ajastettu</option>
                            </Form.Control>
                        </Form.Group>
                        {publishType === 'scheduled' && (
                            <Form.Group>
                                <Form.Label>
                                    <b>Julkaisupäivä</b>
                                </Form.Label>
                                <DatePicker
                                    selected={
                                        new Date(announcement.publishedAt)
                                    }
                                    required
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    onChange={changePublishDate}
                                    timeIntervals={15}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    className="form-control"
                                    locale="fi"
                                />
                            </Form.Group>
                        )}
                    </>
                )}
                {message && !success ? (
                    <Alert variant={!success ? 'danger' : 'success'}>
                        {message}
                    </Alert>
                ) : null}
                <div className="buttons-row">
                    <Link
                        className="btn btn-secondary secondary"
                        to={`/admin/announcements`}
                    >
                        Takaisin
                    </Link>
                    {isLoading && <PreloaderComponent />}
                    <div className="destructive-buttons">
                        <button
                            className="btn btn-success danger"
                            type="button"
                            onClick={() => deleteAnnouncement()}
                        >
                            Poista
                        </button>
                        <button className="btn btn-success" type="submit">
                            Tallenna
                        </button>
                        <button
                            className="btn btn-success"
                            type="button"
                            onClick={() => publish()}
                            disabled={
                                announcement.status === 'published' ||
                                announcement.status === 'archived' ||
                                announcement.status === 'template'
                            }
                        >
                            {publishType === 'now' ? 'Julkaise' : 'Ajasta'}
                        </button>
                    </div>
                </div>
            </ValidationForm>
        </div>
    );
};

export default AnnouncementDetailsView;
