import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import HeaderComponent from '../commons/header/headerComponent';
import MemberListComponent from './memberList/memberListComponent';

import { hasPrivilege } from '../../utils/cookies';
import FooterComponent from '../commons/footer/footerComponent';

class ControlComponent extends Component {
    render() {
        return (
            <div>
                <HeaderComponent />
                {hasPrivilege('boardmember') ||
                hasPrivilege('chairman') ||
                hasPrivilege('vicechairman') ||
                hasPrivilege('admin') ? (
                    <div className="control">
                        <MemberListComponent />
                    </div>
                ) : (
                    <div>
                        <Redirect to="/member" />
                    </div>
                )}
                <FooterComponent />
            </div>
        );
    }
}

export default ControlComponent;
