import React from 'react';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import Moment from 'moment';
import './publicAnnouncementList.css';
import { Alert } from 'react-bootstrap';

const PublicAnnouncementListView = ({
    announcements,
    isLoading,
    onAnnouncementClick,
    message,
    success,
}) => {
    return (
        <div className="announcements">
            <div className="title-row">
                <h2>Tiedotteet</h2>
            </div>

            {message && !success ? (
                <Alert variant={!success ? 'danger' : 'success'}>
                    {message}
                </Alert>
            ) : null}
            {isLoading && <PreloaderComponent />}
            {!isLoading && announcements && announcements.length === 0 && (
                <p>Tiedotteita ei löytynyt.</p>
            )}
            {!isLoading &&
                announcements &&
                announcements.map((announcement) => {
                    return (
                        <div
                            key={announcement.id}
                            className="single-announcement"
                            onClick={() => onAnnouncementClick(announcement.id)}
                        >
                            <div className="single-announcement-details">
                                <h5>
                                    {announcement.titleFI} /{' '}
                                    {announcement.titleEN}
                                </h5>
                                <p>
                                    Julkaistu{' '}
                                    {Moment(announcement.publishedAt).format(
                                        'D.M.YYYY'
                                    )}
                                </p>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default PublicAnnouncementListView;
