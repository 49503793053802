import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import digitlogo from '../../assets/digit.webp';
import '../../assets/scaling-logo.css';

import './mainComponent.css';

class MainComponent extends Component {
    render() {
        return (
            <div
                className="container-fluid auth"
                style={{
                    color: '#cdcdcd',
                }}
            >
                <div className="d-flex justify-content-center">
                    <div
                        className={
                            'jumbotron smalljumbo ' +
                            (this.props.big ? 'bigjumbo' : 'smalljumbo')
                        }
                    >
                        <Link to="/">
                            <img
                                alt="Digit logo"
                                className="mx-auto d-block rotating-logo"
                                src={digitlogo}
                            />
                        </Link>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default MainComponent;
