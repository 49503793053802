import React from 'react';

import { Link } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';

import { hasPrivilege } from '../../../utils/cookies';
import { getPrivilegeString } from '../../../utils/privileges';
import './memberDetailsAdminView.css';

Moment.locale('fi');

const currentDate = new Date();

function membershipUntilFormatter(membershipUntil, currentDate) {
    if (membershipUntil) {
        if (new Date(membershipUntil) <= currentDate) {
            return (
                <div>
                    {Moment(membershipUntil).format('D.M.YYYY')}{' '}
                    <FontAwesomeIcon icon="exclamation-triangle" color="red" />
                </div>
            );
        } else {
            return <div>{Moment(membershipUntil).format('D.M.YYYY')}</div>;
        }
    } else {
        return <div>Jäsenmaksua ei ole maksettu</div>;
    }
}

const MemberDetailsAdminView = ({
    firstName,
    lastName,
    utuAccount,
    email,
    homeTown,
    tyyMember,
    privileges,
    entitledToCap,
    entitledToPatch,
    membershipSince,
    membershipUntil,
    createdAt,
    accepted,
    message,
    success,
    handleRemove,
    memberID,
}) => (
    <div className="container">
        {message ? (
            <Alert variant={!success ? 'danger' : 'success'}>{message}</Alert>
        ) : null}
        <Table striped hover>
            <tbody>
                <tr>
                    <th>Etunimi</th>
                    <td style={{ textTransform: 'capitalize' }}>{firstName}</td>
                </tr>
                <tr>
                    <th>Sukunimi</th>
                    <td>{lastName}</td>
                </tr>
                <tr>
                    <th>UTU-tunnus</th>
                    <td>{utuAccount}</td>
                </tr>
                <tr>
                    <th>Sähköpostiosoite</th>
                    <td>{email}</td>
                </tr>
                <tr>
                    <th>Kotikunta</th>
                    <td>{homeTown}</td>
                </tr>
                <tr>
                    <th>TYYn jäsen</th>
                    <td>
                        {tyyMember ? (
                            <p>
                                Kyllä{' '}
                                <FontAwesomeIcon icon="check" color="green" />
                            </p>
                        ) : (
                            <p>
                                Ei <FontAwesomeIcon icon="times" color="red" />
                            </p>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Fuksipassi pakolliset</th>
                    <td>
                        {entitledToCap ? (
                            <p>
                                Kyllä{' '}
                                <FontAwesomeIcon icon="check" color="green" />
                            </p>
                        ) : (
                            <p>
                                Ei <FontAwesomeIcon icon="times" color="red" />
                            </p>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Fuksipassi harraste</th>
                    <td>
                        {entitledToPatch ? (
                            <p>
                                Kyllä{' '}
                                <FontAwesomeIcon icon="check" color="green" />
                            </p>
                        ) : (
                            <p>
                                Ei <FontAwesomeIcon icon="times" color="red" />
                            </p>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Jäsenyys hyväksytty</th>
                    <td>
                        {accepted ? (
                            <p>
                                Kyllä{' '}
                                <FontAwesomeIcon icon="check" color="green" />
                            </p>
                        ) : (
                            <p>
                                Ei <FontAwesomeIcon icon="times" color="red" />
                            </p>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Rooli</th>
                    <td>{getPrivilegeString(privileges)}</td>
                </tr>

                <tr>
                    <th>Jäsenyys alkanut</th>
                    <td>
                        {membershipSince
                            ? Moment(membershipSince).format('D.M.YYYY')
                            : 'Jäsenmaksua ei ole maksettu'}
                    </td>
                </tr>
                <tr>
                    <th>Jäsenyys päättyy</th>
                    <td>
                        {membershipUntilFormatter(membershipUntil, currentDate)}
                    </td>
                </tr>
                <tr>
                    <th>Tunnus luotu</th>
                    <td>{Moment(createdAt).format('D.M.YYYY')}</td>
                </tr>
            </tbody>
        </Table>
        <div className="buttons-row">
            <Link
                className="btn btn-secondary secondary backbutton"
                to="/admin"
            >
                Takaisin
            </Link>
            {hasPrivilege('boardmember') ||
            hasPrivilege('chairman') ||
            hasPrivilege('vicechairman') ||
            hasPrivilege('admin') ? (
                <div className="destructive-buttons">
                    <Button
                        variant="danger"
                        className="btn btn-danger danger"
                        onClick={handleRemove}
                        style={{ textTransform: 'capitalize' }}
                    >
                        Poista {firstName} {lastName}
                    </Button>
                    <Link
                        className="btn btn-success success"
                        to={`/profile/update/${memberID}`}
                    >
                        Päivitä tietoja
                    </Link>
                </div>
            ) : null}
        </div>
    </div>
);

export default MemberDetailsAdminView;
